import { Button, useMediaQuery } from '@mui/material';
import Header from '../../../../../newComponents/Header/Header';
import styles from './AccountEmplOneClient.module.css';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { TabInfo } from './components/TabInfo/TabInfo';
import { useLazyGetClientByIdQuery, useUpdateOrgMutation } from '../../../../../store/newApi/organizations/organizations';
import { TabDocuments } from './components/TabDocuments/TabDocuments';
import { CLIENTS_PAGE_URL, PAGE_CLIENT_BY_ID } from '../../../../../shared/clients/pageUrls';
import { useNotify } from 'shared/providers/AlertProvider/AlertProvider';

export const AccountEmplOneClient = () => {
	const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('desktop'));
	const navigate = useNavigate();
	const { showNotificaton } = useNotify();
	const { clientId } = useParams();
	const [params] = useSearchParams();
	const step = params.get('step');
	const [getClient, { data: dataClient }] = useLazyGetClientByIdQuery();

	const [updateClient, { isSuccess: isUpdateSuccess, reset: resetUpdate }] = useUpdateOrgMutation();

	const [currentStep, setCurrentStep] = useState(0);

	const handleGoBackList = () => {
		navigate(CLIENTS_PAGE_URL());
	};

	const fetchClient = useCallback(() => {
		if (clientId) {
			getClient(clientId);
		}
	}, [clientId]);

	const renderStep = () => {
		switch (currentStep) {
			case 0:
				return <TabInfo refetchData={fetchClient} data={dataClient} onBack={handleGoBackList} setCurrentStep={setCurrentStep} />;
			case 1:
				return <TabDocuments data={dataClient} onBack={handleGoBackList} />;
			default:
				return null;
		}
	};

	useEffect(() => {
		fetchClient();
	}, [fetchClient]);

	useEffect(() => {
		if (step) setCurrentStep(+step);
	}, [step]);

	const handleConfirmClient = () => {
		updateClient({
			orgId: dataClient?.id,
			is_manager_confirmed: 1,
		}).then((data) => {
			if (!!data.error) {
				showNotificaton({ type: 'error', message: 'Не удалось подтвердить клиента' });
			} else {
				fetchClient();
				showNotificaton({ type: 'success', message: 'Клиент успешно подтвержден' });
			}
		});
	};

	const handleGoMain = useCallback(() => {
		if (clientId) navigate(PAGE_CLIENT_BY_ID(clientId, 0));
	}, [clientId]);

	const handleGoDocs = useCallback(() => {
		if (clientId) navigate(PAGE_CLIENT_BY_ID(clientId, 1));
	}, [clientId]);

	const getHeaderBtns = useMemo(() => {
		let btns = [];
		if (dataClient) {
			if (dataClient?.is_manager_confirmed === 'Одобрен') {
				btns.push('Одобрен');
			} else {
				btns.push(
					<Button variant="contained" onClick={handleConfirmClient}>
						Подтвердить клиента
					</Button>,
				);
			}
		}
		return btns;
	}, [dataClient]);

	return (
		<div style={{ height: '100%', display: 'flex', flexDirection: 'column', width: '100%', overflow: 'auto' }}>
			<Header
				text={dataClient ? `Клиент ${dataClient?.organization_name}` : 'Создать клиента'}
				btnDesktop={getHeaderBtns}
				btnMobile={getHeaderBtns}
			/>
			<div className={styles.content}>
				<div className={styles.tabs}>
					<Button
						variant="contained"
						color={currentStep === 0 ? 'secondary' : 'primary'}
						size={isDesktop ? 'medium' : 'small'}
						onClick={handleGoMain}
					>
						Общее
					</Button>
					<Button
						variant="contained"
						color={currentStep === 1 ? 'secondary' : 'primary'}
						size={isDesktop ? 'medium' : 'small'}
						onClick={handleGoDocs}
						disabled={!dataClient}
					>
						Документы
					</Button>
				</div>
				<div className={styles.steps}>{renderStep()}</div>
			</div>
		</div>
	);
};
