import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { tranformErrorsToRender } from 'shared/api/tranformErrorsToRender';
import { useNotify } from 'shared/providers/AlertProvider/AlertProvider';
import { TERMINAL_PAGE_URL } from 'shared/terminals/pageUrls';
import { useCreateTerminalMutation } from 'store/newApi/terminals/terminals';

export const useHandleCreate = ({ values }) => {
	const navigate = useNavigate();
	const { showNotificaton } = useNotify();
	const [createTerminal, { isSuccess: isCreateSuccess, isLoading: isCreateLoading, reset: resetCreateMutation }] =
		useCreateTerminalMutation();

	const handleCreate = useCallback(
		(value) => {
			const combinedData = {
				...values,
				...value,
			};
			createTerminal({ ...combinedData }).then((data) => {
				if (data.error) {
					showNotificaton({
						type: 'error',
						message: tranformErrorsToRender(data.error?.data, 'Не удалось создать терминал'),
					});
				} else {
					resetCreateMutation();
					showNotificaton({
						type: 'success',
						message: 'Терминал создан',
					});
					navigate(TERMINAL_PAGE_URL(data?.data?.id));
				}
			});
		},
		[createTerminal],
	);

	return {
		handleCreate,
		isCreateSuccess,
		isCreateLoading,
	};
};
