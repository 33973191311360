import { Button, Typography } from '@mui/material';
import { RepairTasksInputAutocomplete } from '../../../../../../../newComponents/Form/Fields/RepairTasksInputAutocomplete';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import styles from './ChooseServicesStep.module.css';
import InputText from '../../../../../../../newComponents/Inputs/InputText/InputText';

const isStepEditable = (stepName) => {
	return ['ТЕУ/ДФЭ', 'Штуки'].includes(stepName);
};

export const ChooseRepairTasks = ({
	repairTasks,
	disableEdit,
	handleChangeStep,
	handleChangeTaskLaborPrice,
    handleChangeTaskMateralPrice,
	showAddForm,
	setShowAddForm,
	totalPrice,
	containerInfo,
	handleAddRepairTask,
	price,
	handleChangePercent,
	discount,
	discountValue,
	handleUpdatePercent,
	handleUpdatePrice,
	handleDeleteTask,
	totalMaterialPrice,
	totalLaborPrice,
}) => {
	return (
		<>
			<Typography variant="h6">Выбор услуги</Typography>
			<div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, justifyContent: 'space-between' }}>
				<div style={{ display: 'flex', flexDirection: 'column', gap: '5px', marginTop: '12px' }}>
					{repairTasks.map((rt) => {
						return (
							<div className={styles.serviceItem} key={rt.id}>
								<div className={styles.serviceInfo} style={{ display: 'flex', alignItems: 'flex-end', gap: '5px', flexGrow: 1 }}>
									<Button
										disabled={disableEdit}
										variant="outlined"
										color="primary"
										size="medium"
										onClick={() => handleDeleteTask(rt.id)}
									>
										<DeleteIcon />
									</Button>
									{/* @ts-ignore */}
									<InputText value={`${rt.repair_component}: ${rt.repair_task_display}`} labelText="Услуга" disabled />
								</div>
								<div className={styles.serviceInfo} style={{ display: 'flex', alignItems: 'flex-end', gap: '5px' }}>
									{/* @ts-ignore */}
									<InputText
										defaultValue={rt.steps}
										labelText="Шаги"
										onBlur={(e) => handleChangeStep(rt.id, e.target.value)}
										disabled={!isStepEditable(rt.repair_task_unit) || disableEdit}
									/>
									{/* @ts-ignore */}
									<InputText
										defaultValue={rt.total_labor_price}
										labelText="Стоимость работ"
										onBlur={(e) => handleChangeTaskLaborPrice(rt.id, e.target.value)}
										disabled={!isStepEditable(rt.repair_task_unit) || disableEdit}
									/>
									{/* @ts-ignore */}
									<InputText
										defaultValue={rt.total_material_price}
										labelText="Стоимость материалов"
										onBlur={(e) => handleChangeTaskMateralPrice(rt.id, e.target.value)}
										disabled={!isStepEditable(rt.repair_task_unit) || disableEdit}
									/>
								</div>
							</div>
						);
					})}
					{showAddForm && (
						<div className={styles.serviceInfo} style={{ display: 'flex', alignItems: 'flex-end', gap: '5px', flexGrow: 1 }}>
							<Button variant="outlined" color="primary" size="medium" onClick={() => setShowAddForm(false)}>
								<DeleteIcon />
							</Button>
							<RepairTasksInputAutocomplete
								searchParams={{ terminal_id: containerInfo?.terminal }}
								labelText="Услуга"
								onChange={handleAddRepairTask}
							/>
						</div>
					)}
					{!showAddForm && !disableEdit && (
						<Button
							variant="contained"
							color="primary"
							size="medium"
							sx={{ mt: '12px', width: '42px' }}
							onClick={() => setShowAddForm(true)}
						>
							<AddIcon />
						</Button>
					)}
				</div>
				<div style={{ display: 'flex', flexDirection: 'column', gap: '5px', marginTop: '10px' }}>
					{!!totalPrice && <div>{`Общая стоимость: ${totalPrice}`}</div>}
					{!!totalLaborPrice && <div>{`Стоимость работ: ${totalLaborPrice} ₽`}</div>}
					{!!totalMaterialPrice && <div>{`Стоимость материалов: ${totalMaterialPrice} ₽`}</div>}
				</div>
			</div>
		</>
	);
};
