import { useCallback, useEffect } from 'react';
import { tranformErrorsToRender } from 'shared/api/tranformErrorsToRender';
import { useNotify } from 'shared/providers/AlertProvider/AlertProvider';
import { useUpdateExtraservicesMutation } from 'store/newApi/extraservices/extraservices';

export const useHandleUpdate = ({ entityIds, handleClose, refetchData, ...methods }) => {
	const { showNotificaton } = useNotify();
	const {
		formState: { touchedFields },
		reset: resetForm,
	} = methods;
	const [updateExtraservices, { isSuccess: isUpdateSuccess, isLoading: isUpdateLoading, reset: resetUpdateExtraservicesMutation }] =
		useUpdateExtraservicesMutation();

	useEffect(() => {
		if (isUpdateSuccess) {
			resetUpdateExtraservicesMutation();
			handleClose();
			resetForm();
			refetchData();
		}

		return () => {
			resetForm();
		};
	}, [isUpdateSuccess, handleClose, refetchData, resetUpdateExtraservicesMutation, resetForm]);

	const handleUpdate = useCallback(
		(value) => {
			const isServiceSelected = value.service && typeof value.service === 'object';
			const payload = {
				service_orders: entityIds,
				quantity: value.quantity && Number(value.quantity),
				price_per_unit: value.price_per_unit && Number(value.price_per_unit),
				...(isServiceSelected ? { service: value.service.id, extra_service_name: null } : { service: null, extra_service_name: value.extra_service_name || value.service }),
			};
			updateExtraservices({
				...payload,
			}).then((data) => {
				if (data.error) {
					showNotificaton({
						type: 'error',
						message: tranformErrorsToRender(data.error?.data, 'Не удалось обновить услугу'),
					});
				} else {
					resetUpdateExtraservicesMutation();
					handleClose();
					resetForm();
					refetchData();
					showNotificaton({
						type: 'success',
						message: 'Услуга успешно обновлена',
					});
				}
			});
		},
		[entityIds, updateExtraservices, touchedFields],
	);

	return {
		handleUpdate,
		isUpdateSuccess,
		isUpdateLoading,
	};
};
