import { Box, Button, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CollapseBlock } from '../../CollapseBlock/CollapseBlock';
import InputText from '../../Inputs/InputText/InputText';
import { DrawerModal } from '../../DrawerModal/DrawerModal';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormWrapper } from '../FormWrapper';
import { useHandleUpdate } from './hooks/useHandleUpdate';
import { ContainerInputAutocomplete } from '../Fields/ContainerInputAutocomplete';
import { useHandleCreate } from './hooks/useHandleCreate';
import { ExtraservicesInputAutoComplete } from '../Fields/ExtraservicesInputAutoComplete/ExtraservicesInputAutoComplete';
import { useLazyGetContainerByIdQuery } from 'store/newApi/containers/containers';
import { useInitFormState } from './hooks/useInitFormState';

const updateSchema = z.object({
	service: z.any().optional(),
	quantity: z.any().optional(),
	price_per_unit: z.any().optional(),
});

const createSchema = z.object({
	container: z.number({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
	service: z.any().optional(),
	quantity: z.string({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
	price_per_unit: z.string({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
});

export const ExtraServicesForm = ({ isOpen, handleClose, refetchData, entityIds = [] }) => {
	const isCreate = useMemo(() => entityIds.length === 0, [entityIds]);
	const resolver = useMemo(() => (isCreate ? zodResolver(createSchema) : zodResolver(updateSchema)), [isCreate]);

	const methods = useForm({
		defaultValues: {
			container: undefined,
			extra_service_name: undefined,
			quantity: undefined,
			price_per_unit: undefined,
			service: undefined,
		},
		resolver: resolver,
	});

	const { handleCreate, isCreateLoading } = useHandleCreate({ ...methods, refetchData, handleClose });
	const { handleUpdate, isUpdateLoading } = useHandleUpdate({ ...methods, entityIds, refetchData, handleClose });
	useInitFormState({ ...methods, ids: entityIds, isReady: isOpen });

	const {
		handleSubmit,
		control,
		watch,
		formState: { errors },
	} = methods;

	const onSubmit = useCallback(
		(value) => {
			if (isCreate) handleCreate(value);
			else handleUpdate(value);
		},
		[handleCreate, handleUpdate, isCreate],
	);

	const containerId = watch('container');
	const isQuantity = watch('quantity');
	const isPriceUnit = watch('price_per_unit');

	const [fetchContainer, { data: dataContainer }] = useLazyGetContainerByIdQuery();

	useEffect(() => {
		if (containerId) {
			fetchContainer({ id: containerId });
		}
	}, [containerId]);

	const [totalPrice, setTotalPrice] = useState(0);

	useEffect(() => {
		if (isQuantity && isPriceUnit) {
			setTotalPrice(Number(isQuantity) * Number(isPriceUnit));
		} else {
			setTotalPrice(0);
		}
	}, [isQuantity, isPriceUnit]);

	return (
		<DrawerModal
			isOpen={isOpen}
			handleClose={handleClose}
			actions={
				// @ts-ignore
				<Button isLoading={isUpdateLoading} onClick={handleSubmit(onSubmit)} variant="contained" color="primary">
					Сохранить
				</Button>
			}
		>
			<Typography variant="subtitle1">{isCreate ? 'Добавление услуги' : 'Редактирование услуги'}</Typography>
			<FormWrapper onSubmit={onSubmit} {...methods}>
				{isCreate && (
					<CollapseBlock defaultOpen={true || !!errors.container} title={<span>№ Контейнера</span>}>
						<Controller
							name="container"
							control={control}
							render={({ field }) => (
								<>
									<ContainerInputAutocomplete {...field} searchParams={{ status: [2, 3, 4] }} />
									{errors?.container && (
										<Typography color="error" variant="caption"> 
											{/* @ts-ignore */}
											{errors?.container?.message}
										</Typography>
									)}
								</>
							)}
						/>
					</CollapseBlock>
				)}
				<CollapseBlock title="Услуга" defaultOpen={true || !!errors.service}>
					<Controller
						name="service"
						control={control}
						render={({ field }) => (
							<>
								{/* @ts-ignore */}
								<ExtraservicesInputAutoComplete
									{...field}
									value={field.value}
									onChange={(newValue) => field.onChange(newValue)}
									searchParams={isCreate ? (dataContainer ? { terminal_id: dataContainer.terminal } : null) : { terminal_id: 1 }}
								/>
								{errors?.service && (
									<Typography color="error" variant="caption">
										{/* @ts-ignore */}
										{errors?.service?.message}
									</Typography>
								)}
							</>
						)}
					/>
				</CollapseBlock>
				<CollapseBlock title="Количество" defaultOpen={true || !!errors.quantity}>
					<Controller
						name="quantity"
						control={control}
						render={({ field }) => (
							//  @ts-ignore 
							<InputText {...field} type="number" placeholder="Введите количество" errorMessage={errors?.quantity?.message} />
						)}
					/>
				</CollapseBlock>
				<CollapseBlock title="Цена за единицу" defaultOpen={true || !!errors.price_per_unit}>
					<Controller
						name="price_per_unit"
						control={control}
						render={({ field }) => (
							//  @ts-ignore 
							<InputText {...field} type="number" placeholder="Введите цену" errorMessage={errors?.price_per_unit?.message} />
						)}
					/>
				</CollapseBlock>

				<Box sx={{ marginTop: '18px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
					<Box>Стоимость</Box>
					<Box>{`${totalPrice} ₽`}</Box>
				</Box>
			</FormWrapper>
		</DrawerModal>
	);
};
