import React, { useEffect, useRef, useState } from 'react';
import styles from '../AccountEmpl.module.scss';
import Header from '../../../../newComponents/Header/Header';
import { Grid, Container, Button, Box } from '@mui/material';
import { useLazyGetOrdersListQuery } from '../../../../store/newApi/orders/orders';
import { useGetTerminalsListQuery } from '../../../../store/newApi/terminals/terminals';
import { Link, useNavigate } from 'react-router-dom';
import { formatInitialDate, formatDate } from '../../../../helpers/formatDate';
import { account, accountGridItemHalf } from './Account.styles.js';
import useMediaQuery from '@mui/material/useMediaQuery';
import Preloader from '../../../../components/Preloader/Preloader';
import BtnDropdown from '../../../../newComponents/BtnDropdown/BtnDropdown.jsx';
 
import { useDispatch, useSelector } from 'react-redux';
import { FILTER_TYPES } from '../../../../config/filter';
import { resetFilter, setFilter } from '../../../../store/rtkSlices/orders.js';
import { CREATE_ORDER_DELIVERY_URL, CREATE_ORDER_RECIEV_URL, ORDER_INFO_URL } from '../../../../shared/orders/pageUrls.js';
 
import { OrderCard } from '../../../../entities/order/ui/OrderCard/OrderCard';
import { ORDER_EXPEDITION, ORDER_STATUS_TEXT, ORDER_TYPE_IN_OUT } from '../../../../shared/orders/constants';
import { Filters } from '../../../../shared/ui/Filters/Filters';
import { isEmpl } from 'shared/auth/isEmpl';

function AccountEmplOrders() {
	const dispatch = useDispatch();
	const filters = useSelector((state) => state['ordersRtk']?.filter) ?? {};
	const [trigger, { data, isLoading }] = useLazyGetOrdersListQuery({});
	const [dataCards, setDataCards] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const containerRef = useRef(null);
	const [fetching, setFetching] = useState(false);
	const navigate = useNavigate();
	const [filtering, setFiltering] = useState(false);

	const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('desktop'));

	// Самая первая загрузка

	useEffect(() => {
		trigger({ page: 1, page_size: 20 });
		setCurrentPage(1);
	}, []);

	useEffect(() => {
		const { page, page_size, ...restFilters } = filters;
		if (Object.keys(restFilters).length === 0) return;
		trigger({ ...filters, page: 1, page_size: 20, uniq: Math.random() });
		setCurrentPage(1);
		setFiltering(true);
	}, [filters]);

	// Infinite scroll
	useEffect(() => {
		const handleScroll = () => {
			if (containerRef.current && !fetching && !isLoading) {
				const { scrollTop, scrollHeight, clientHeight } = containerRef.current;

				if (scrollTop + clientHeight >= scrollHeight - 5) {
					if (data?.count > dataCards?.length) {
						setFetching(true);
						const nextPage = currentPage + 1;
						trigger({ ...filters, page: nextPage, page_size: 20 });
						setCurrentPage(nextPage);
					}
				}
			}
		};

		const container = containerRef.current;
		container.addEventListener('scroll', handleScroll);

		return () => {
			container.removeEventListener('scroll', handleScroll);
		};
	}, [currentPage, fetching, isLoading, data, dataCards]);

	// Добавление подгруженных данных после скролла

	useEffect(() => {
		if (data && data.results) {
			setDataCards((prevData) => {
				if (filtering) {
					setFiltering(false);
					return [...data.results];
				} else return [...prevData, ...data.results];
			});
			setFetching(false);
		}
	}, [data]);

	const menuItems = [
		{
			text: 'Приём контейнеров',
			onClick: () => {
				navigate(CREATE_ORDER_RECIEV_URL());
			},
		},
		{
			text: 'Выдача контейнеров',
			onClick: () => {
				navigate((CREATE_ORDER_DELIVERY_URL()));
			},
		},
	];

	const handleSetFilter = (filters) => {
		dispatch(setFilter(filters));
	};

	const handleResetFilter = () => {
		dispatch(resetFilter());
	};

	const { data: dataTerminals } = useGetTerminalsListQuery({});
	const [elements, setElements] = useState([]);

	useEffect(() => {
		if (Array.isArray(dataTerminals)) {
			const newElements = dataTerminals.map((item) => ({
				value: item.id,
				label: item.name,
			}));
			setElements(newElements);
		}
	}, [dataTerminals]);

	return (
		<div className={styles.account__wrap}>
			<Header
				text={'Заявки на контейнеры'}
				btnDesktop={[<BtnDropdown mainButtonText="Добавить заявку" dropdownItems={menuItems} />]}
				btnMobile={[<BtnDropdown mainButtonText="Добавить заявку" dropdownItems={menuItems} />]}
				filter={
					<Filters
						onSetFilter={handleSetFilter}
						onResetFilter={handleResetFilter}
						parameters={[
							{
								name: 'order_type',
								title: 'Тип',
								type: FILTER_TYPES.CHECKBOX,
								elements: [
									{ value: 1, label: 'Ввоз' },
									{ value: 2, label: 'Вывоз' },
								],
							},
							{
								name: 'order_status',
								title: 'Статус',
								type: FILTER_TYPES.CHECKBOX,
								elements: [
									{ value: 0, label: 'Создана' },
									{ value: 1, label: 'Принята' },
									{ value: 2, label: 'В работе' },
									{ value: 3, label: 'Завершена' },
								],
							},
							{
								name: 'terminal_id',
								title: 'Терминал',
								type: FILTER_TYPES.INPUTDROPDOWN,
								elements: elements,
							},
							isEmpl() && {
								name: 'client_id',
								title: 'Клиент',
								type: FILTER_TYPES.INPUTDROPDOWNCLIENT,
							},
							{
								name: ['start_date', 'end_date'],
								title: 'Дата создания',
								type: FILTER_TYPES.INPUTDATERANGE,
								elements: [],
							},
						]}
					/>
				}
			/>
			<div className={styles.account__container}>
				<Container ref={containerRef} className={styles.account__collection} sx={account.container}>
					{/* <div>
						<Button onClick={() => setSelectionMode(!selectionMode)}>{selectionMode ? 'Отмена' : 'Выбрать'}</Button>
					</div> */}
					{isLoading ? (
						<Box sx={account.preloader}>
							<Preloader />
						</Box>
					) : (
						<Grid container>
							{dataCards?.map((card, index) => (
								<Grid item sx={accountGridItemHalf(isDesktop)} key={index}>
									<Link to={ORDER_INFO_URL(card?.terminal?.id, card?.id)}>
										<OrderCard
											orderId={card?.id}
											status={ORDER_STATUS_TEXT[card?.status]}
											colorStatus={card?.status == 3 ? 'success' : 'primary'}
											client={card?.client?.organization_name}
											expedition={ORDER_EXPEDITION[card?.expedition_type]}
											type={ORDER_TYPE_IN_OUT[card?.type]}
											arrived={card?.completion?.arrived}
											canceled={card?.completion?.canceled}
											expected={card?.completion?.expected}
											total={card?.completion?.total}
											dateCreated={card?.created_at ? formatInitialDate(card?.created_at) : '-'}
											dateComplete={card?.eta ? formatDate(card?.eta) : '-'}
										/>
									</Link>
								</Grid>
							))}
						</Grid>
					)}
				</Container>
			</div>
		</div>
	);
}
export default AccountEmplOrders;
