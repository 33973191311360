import { useGetPermissions } from '../../../../../../../shared/auth/hooks/useGetPermissions';
import { OrgEmplForm } from './OrgEmplForm';
import { TerminalEmplForm } from './TerminalEmplForm';

export const TabInfo = ({ ...props }) => {
	const { isClient } = useGetPermissions();

	if (isClient())
		return <OrgEmplForm {...props} />
	return <TerminalEmplForm {...props} />
};
