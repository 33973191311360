import styles from './Tabs.module.scss';
import { Button, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import InputText from '../../../../../../newComponents/Inputs/InputText/InputText';
import { useCallback, useEffect, useMemo } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormWrapper } from '../../../../../../newComponents/Form/FormWrapper';
import RequireCheckRights from '../../../../../../hoc/RequireCheckRights';
import { useInitFormState } from '../hooks/useInitFormState';
import { TerminalScheduleInput } from './TerminalScheduleInput/TerminalScheduleInput';
import { createSchema, updateSchema, defaultValuesForm } from './schemes';
import { useHandleUpdate } from '../hooks/useHandleUpdate';
import { useLazyGetTerminalDetailQuery } from 'store/newApi/terminals/terminals';
import { useParams } from 'react-router-dom';
import { transformSchedule } from '../hooks/utils';

export const TabTerminalInfo = ({ onBack, setCurrentStep, values, setValues }) => {
	const { terminalId } = useParams();
	const [getTerminalDetail, { data: dataTerminalDetail }] = useLazyGetTerminalDetailQuery();

	useEffect(() => {
		if (terminalId) {
			getTerminalDetail(terminalId);
		}
	}, [terminalId]);

	const resolver = useMemo(() => (dataTerminalDetail ? zodResolver(updateSchema) : zodResolver(createSchema)), [dataTerminalDetail]);
	const methods = useForm({
		defaultValues: defaultValuesForm,
		resolver: resolver,
	});

	const {
		handleSubmit,
		control,
		setValue,
		formState: { errors },
	} = methods;

	useInitFormState({ data: dataTerminalDetail ? dataTerminalDetail : values, ...methods });
	const { handleUpdate } = useHandleUpdate({ ...methods, terminalId: dataTerminalDetail?.id });

	const onSubmit = useCallback(
		(value) => {
			if (dataTerminalDetail) {
				handleUpdate(value);
			} else {
				const scheduleFilter = transformSchedule(value.schedule);
				const { schedule, ...restValue } = value;
				const combinedData = {
					...restValue,
					schedule: scheduleFilter,
				};
				setValues(combinedData);
				setCurrentStep(1);
			}
		},
		[dataTerminalDetail, handleUpdate, setValues, setCurrentStep],
	);

	return (
		<div style={{ display: 'flex', flexDirection: 'column', minHeight: '100%', flexGrow: 1, justifyContent: 'space-between' }}>
			<div style={{ margin: '16px' }}>
				<FormWrapper onSubmit={onSubmit} {...methods}>
					<div className={styles.containerFormTemplate}>
						<Controller
							name="name"
							control={control}
							render={({ field }) => (
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									<InputText {...field} labelText="Название терминала" placeholder="Введите название" />
									{errors?.name && (
										<Typography color="error" variant="caption">
											{errors?.name?.message}
										</Typography>
									)}
								</div>
							)}
						/>
						<Controller
							name="address"
							control={control}
							render={({ field }) => (
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									<InputText {...field} labelText="Адрес терминала" placeholder="Введите адрес" />
									{errors?.address && (
										<Typography color="error" variant="caption">
											{errors?.address?.message}
										</Typography>
									)}
								</div>
							)}
						/>
						<Controller
							name="max_teu"
							control={control}
							render={({ field }) => (
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									<InputText
										{...field}
										type="number"
										labelText="Вместимость терминала (TEU)"
										placeholder="Введите вместимость"
										onChange={(e) => field.onChange(Number(e.target.value))}
									/>
									{errors?.max_teu && (
										<Typography color="error" variant="caption">
											{errors?.max_teu?.message}
										</Typography>
									)}
								</div>
							)}
						/>
						<Controller
							name="contact_number"
							control={control}
							render={({ field }) => (
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									<InputText {...field} labelText="Номер телефона" placeholder="Введите номер" />
									{errors?.contact_number && (
										<Typography color="error" variant="caption">
											{errors?.contact_number?.message}
										</Typography>
									)}
								</div>
							)}
						/>
					</div>
					<div className={styles.schedule}>
						<Typography variant="btnMedium">График работы терминала</Typography>
						<div className={styles.scheduleContainer}>
							<TerminalScheduleInput title="Понедельник" day="Пн" />
							<TerminalScheduleInput title="Вторник" day="Вт" />
							<TerminalScheduleInput title="Среда" day="Ср" />
							<TerminalScheduleInput title="Четверг" day="Чт" />
							<TerminalScheduleInput title="Пятница" day="Пт" />
							<TerminalScheduleInput title="Суббота" day="Сб" />
							<TerminalScheduleInput title="Воскресенье" day="Вс" />
						</div>
					</div>
				</FormWrapper>
			</div>
			<div
				style={{
					justifyContent: 'space-between',
					width: '100%',
					display: 'flex',
					padding: '5px 11px',
					position: 'sticky',
					bottom: 0,
					background: '#fff',
					zIndex: '1000',
					borderTop: '1px solid #ddd',
					maxHeight: '50px',
				}}
			>
				<Button variant="outlined" color="primary" size="medium" onClick={onBack}>
					Назад
				</Button>

				{dataTerminalDetail ? (
					<RequireCheckRights path="terminals" type="change">
						<Button variant="contained" color="primary" size="medium" onClick={handleSubmit(onSubmit)}>
							Сохранить
						</Button>
					</RequireCheckRights>
				) : (
					<Button variant="contained" color="primary" size="medium" onClick={handleSubmit(onSubmit)}>
						Далее
					</Button>
				)}
			</div>
		</div>
	);
};
