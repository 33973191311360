import BtnDropdown from "newComponents/BtnDropdown/BtnDropdown";
import { useNavigate } from "react-router-dom";
import { isEmpl } from "shared/auth/isEmpl"
import { CREATE_INSPECTION_DELIVERY_URL, CREATE_INSPECTION_RECIEV_URL } from "shared/inspection/pageUrls";

export const InspectionCreateButton = () => {
    const navigate = useNavigate();

    const menuItems = [
		{
			text: 'Приём контейнера',
			onClick: () => {
				navigate(CREATE_INSPECTION_RECIEV_URL());
			},
		},
		{
			text: 'Выдача контейнера',
			onClick: () => {
				navigate(CREATE_INSPECTION_DELIVERY_URL());
			},
		},
	];

    if (!isEmpl())
        return null;

    return <BtnDropdown mainButtonText="Добавить акт" dropdownItems={menuItems} />;
}