import style from './SideBarModal.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import {
	setCurrentElementForSideBar,
	setNon_activeWrapper,
	setPrevElementForSideBar,
	setSideBarActive,
} from '../../store/slices/sideBarSlice';
import UniversalButton from '../Buttons/UniversalButton/UniversalButton';
import { IconArrowLeft, IconCross } from '../Icons';
import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';

export default function SideBarModal() { 
	const { currentElement } = useSelector((state) => state.sideBar);
	const { sideBarActive } = useSelector((state) => state.sideBar);
	const { prevElement } = useSelector((state) => state.sideBar);
	const { non_activeWrapper } = useSelector((state) => state.sideBar);
	const { height_fitContent } = useSelector((state) => state.sideBar);

	// отслеживание ширины окна
	const isDesktop = useMediaQuery({ minWidth: 1279.9 });

	const location = useLocation();
	const dispatch = useDispatch();

	const handleModalClose = () => {
		// добавила для исчезновения кнопок при несохранненых изменениях в инпутах клиента
		dispatch(setSideBarActive(false));
		dispatch(setPrevElementForSideBar(null));
		dispatch(setNon_activeWrapper(false));
	};

	const handleModalBack = () => {
		dispatch(setCurrentElementForSideBar(prevElement));
		dispatch(setPrevElementForSideBar(null));
		dispatch(setNon_activeWrapper(false));
	};

	//! Закрытие сайдбара при уходе со страницы
	useEffect(() => {
		return () => {
			dispatch(setSideBarActive(false));
		};
	}, [location, dispatch]);

	return (
		<div
			className={`${isDesktop ? (non_activeWrapper ? style.wrapper__noneActive : style.wrapper) : sideBarActive ? style.wrapper_mobActive : style.wrapper_mob}`}
		>
			<div
				className={`${sideBarActive ? style.active : style.non_active}`}
				style={height_fitContent ? { height: 'fit-content' } : null}
			>
				<div className={style.sideBar__closeBtn}>
					<UniversalButton icon={<IconCross color={'black'} />} size={'large'} onClick={handleModalClose} />
				</div>
				{prevElement && (
					<div className={style.sideBar__backBtn}>
						<UniversalButton icon={<IconArrowLeft color={'black'} />} size={'large'} onClick={handleModalBack} />
					</div>
				)}
				{/* {sideBarActive ? <>{currentElement}</> : ''} */}
				{currentElement}
			</div>
		</div>
	);
}
