import { Checkbox } from '@mui/material';
import { Switcher } from 'components';
import InputText from 'newComponents/Inputs/InputText/InputText';
import { Controller, useFormContext } from 'react-hook-form';
import styles from './TerminalScheduleInput.module.css';
import { useEffect } from 'react';

type TerminalScheduleInputProps = {
	title: string;
	day: string;
};

export const TerminalScheduleInput = ({ title, day }: TerminalScheduleInputProps) => {
	const { watch, control, setValue } = useFormContext();

	const isEnabled = watch(`schedule.${day}.enabled`);
	const aroundClock = watch(`schedule.${day}.aroundClock`);

	useEffect(() => {
		if (!isEnabled) {
			setValue(`schedule.${day}.aroundClock`, false);
		}
	}, [isEnabled]);

	return (
		<div className={styles.scheduleContainer}>
			<div className={styles.scheduleTitle}>
				<Controller
					name={`schedule.${day}.enabled`}
					control={control}
					render={({ field }) => (
						<Checkbox
							checked={field.value}
							onChange={(e) => field.onChange(e.target.checked)}
							sx={{
								color: '#5C53A7',
								'&.Mui-checked': {
									color: '#5C53A7',
								},
							}}
						/>
					)}
				/>
				<span>{title}</span>
			</div>
			{/* @ts-ignore */}
			<Switcher
				onChecked={(checked) => setValue(`schedule.${day}.aroundClock`, checked, { shouldTouch: true })}
				externalChecked={aroundClock}
				disabled={!isEnabled}
				right="Круглосуточно"
			/>
			{isEnabled && !aroundClock && (
				<div className={styles.scheduleInputs}>
					<Controller
						name={`schedule.${day}.start`}
						control={control}
						render={({ field }) => (
							// @ts-ignore
							<InputText {...field} placeholder="08:00" />
						)}
					/>

					<Controller
						name={`schedule.${day}.end`}
						control={control}
						render={({ field }) => (
							// @ts-ignore
							<InputText {...field} placeholder="18:00" />
						)}
					/>
				</div>
			)}
		</div>
	);
};
