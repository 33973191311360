import { Typography, Box, Grid, Divider, Chip } from '@mui/material';
import { Card } from '../../../../shared/ui/Card/Card';

type EntryCardProps = {
	name: string;
	status: string;
	colorStatus: any;
	type: string;
	client: string;
	transporter: string;
	order: number;
	terminal: string;
	dateCheckin: string;
	driver: string;
	containerNumber: string;
	vehicle: string;
	trailerNumber: string;
	sealNumber: string;
};

export const EntryCard = ({
	name,
	status,
	colorStatus,
	type,
	client,
	transporter,
	order,
	terminal,
	dateCheckin,
	driver,
	containerNumber,
	vehicle,
	trailerNumber,
	sealNumber,
}: EntryCardProps) => {
	return (
		<Card>
			<Box sx={{ width: '100%' }}>
				<Box sx={{ padding: '24px 24px 12px', display: 'flex', flexDirection: 'column', gap: '16px', width: '100%' }}>
					<Grid container sx={{ justifyContent: 'space-between' }}>
						<Grid item>
							<Typography variant="subtitle1">{name}</Typography>
						</Grid>

						<Grid item>
							<Chip label={status} color={colorStatus} />
						</Grid>
					</Grid>

					<Typography variant="subtitle2">{transporter}</Typography>

					<Grid container sx={{ gap: '12px' }}>
						<Grid item>
							<Chip label={`Заявка: №${order}`} />
						</Grid>

						<Grid item>
							<Chip label={`Терминал: ${terminal}`} />
						</Grid>

						<Grid item>
							<Chip label={`Клиент: ${client}`} />
						</Grid>
						<Grid item>
							<Chip label={`Тип: ${type}`} />
						</Grid>
					</Grid>

					<Grid container sx={{ justifyContent: 'space-between' }}>
						<Grid item sx={{ display: 'flex', flexDirection: 'row', gap: '4px' }}>
							<Typography variant="body1">Ожидаемая дата проезда:</Typography>
							<Typography variant="body2">{dateCheckin}</Typography>
						</Grid>
					</Grid>
					<Grid container sx={{ justifyContent: 'space-between' }}>
						<Grid item sx={{ display: 'flex', flexDirection: 'row', gap: '4px' }}>
							<Typography variant="body1">Водитель:</Typography>
							<Typography variant="body2">{driver}</Typography>
						</Grid>
					</Grid>
				</Box>
				<Divider />
				<Box
					sx={{
						padding: '16px 24px',
					}}
				>
					<Grid container sx={{ justifyContent: 'space-between' }}>
						<Grid item sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
							<Typography variant="caption">Номер контейнера</Typography>
							<Typography variant="overline">{containerNumber}</Typography>
						</Grid>
						<Grid item sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
							<Typography variant="caption">Номер а/м</Typography>
							<Typography variant="overline">{vehicle}</Typography>
						</Grid>
						<Grid item sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
							<Typography variant="caption">Прицеп</Typography>
							<Typography variant="overline">{trailerNumber}</Typography>
						</Grid>
						<Grid item sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
							<Typography variant="caption">Пломба</Typography>
							<Typography variant="overline">{sealNumber}</Typography>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</Card>
	);
};
