export const ORDER_NAME = {
    DELIVERY: 'Выдача',
    RECIEV: 'Прием'
}
export const ORDER_TYPE = {
    [ORDER_NAME.RECIEV]: {
        id: 1,
        title: 'Прием контейнера',
    },
    [ORDER_NAME.DELIVERY]: {
        id: 2,
        title: 'Выдача контейнера',
    }
}

const ORDER_STATUSES = {
    CREATED: 0,
    ACCEPTED: 1,
    IN_PROGRESS: 2,
    FINISHED: 3,
}

export const isFinishedStatus = (status) => status === ORDER_STATUSES.FINISHED;

export const ORDER_TYPE_BY_ID = {
    1: 'прием контейнера',
    2: 'выдачу контейнера'
}

export const ORDER_STATUS_TEXT = {
    0: 'Создана',
    1: 'Принята',
    2: 'В работе',
    3: 'Завершена',
};

export const ORDER_EXPEDITION = {
    0: 'Автомобиль',
    1: 'Поезд', 
};

export const ORDER_TYPE_IN_OUT = {
    1: 'Ввоз',
    2: 'Вывоз', 
};