export const transformSchedule = (schedule) => {
	const newSchedule = {};

	Object.entries(schedule).forEach(([day, times]) => {
		const { enabled, aroundClock, start, end } = times;
		if (enabled && aroundClock) {
			newSchedule[day] = { start: '24', end: '24' };
		} else if (enabled) {
			newSchedule[day] = { start: start || '-', end: end || '-' };
		} else {
			newSchedule[day] = { start: '-', end: '-' };
		}
	});

	return newSchedule;
};
