import styles from './TabInfo.module.css';
import { Button, Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { useHandleCreate } from './hooks/useHandleCreate';
import { useHandleUpdate } from './hooks/useHandleUpdate';
import { useInitFormState } from './hooks/useInitFormState';
import { FormWrapper } from '../../../../../../../newComponents/Form/FormWrapper';
import InputText from '../../../../../../../newComponents/Inputs/InputText/InputText';
import { Switcher } from '../../../../../../../components';
import { useGetTerminalsListQuery } from '../../../../../../../store/newApi/terminals/terminals';
import { useNavigate } from 'react-router-dom';
import { PAGE_CLIENT_BY_ID } from '../../../../../../../shared/clients/pageUrls';
import { AssigneesInputAutocomplete } from '../../../../../../../newComponents/Form/Fields/AssigneesInputAutocomplete';
import { filterAssignees } from '../../../../../../../shared/empls/utils';
import { LegalEntitiesInputAutocomplete } from 'newComponents/Form/Fields/LegalEntitiesInputAutocomplete';

const createSchema = z.object({
	full_name: z.string({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
	password: z.string({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
	organization_name: z.string({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
	email: z.string({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
	inn: z.string({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
	organization_address: z.string({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
	isCan_confirm_repair: z.boolean().optional(),
	isCan_see_storage_price: z.boolean().optional(),
	terminals: z.any().optional(),
	assignee: z.any().optional(),
	legalEntity: z.any().optional(),
});

const updateSchema = z.object({
	full_name: z.string().optional(),
	password: z.string().optional(),
	organization_name: z.string().optional(),
	email: z.string().optional(),
	inn: z.string().optional(),
	organization_address: z.string().optional(),
	isCan_confirm_repair: z.boolean().optional(),
	isCan_see_storage_price: z.boolean().optional(),
	terminals: z.any().optional(),
	assignee: z.any().optional(),
	legalEntity: z.any().optional(),
});

export const TabInfo = ({ data, onBack, refetchData }) => {
	const navigate = useNavigate();
	const isEditMode = useMemo(() => !!data, [data]);
	const resolver = useMemo(() => (data ? zodResolver(updateSchema) : zodResolver(createSchema)), [data]);

	const methods = useForm({
		defaultValues: {
			full_name: '',
			password: '',
			organization_name: '',
			email: '',
			inn: '',
			organization_address: '',
			isCan_confirm_repair: false,
			isCan_see_storage_price: false,
			terminals: [],
			assignee: '',
			legalEntity: '',
		},
		resolver: resolver,
	});

	const {
		handleSubmit,
		control,
		setValue,
		watch,
		formState: { errors },
		setError,
		clearErrors,
	} = methods;

	const { handleCreate, isCreateLoading, isCreateSuccess, data: clientData } = useHandleCreate({ ...methods });
	const { handleUpdate, isUpdateLoading } = useHandleUpdate({ ...methods, refetchData });
	useInitFormState({ data, ...methods });

	const isCan_confirm_repair = watch('isCan_confirm_repair');
	const isCan_see_storage_price = watch('isCan_see_storage_price');

	// Состояние выбранных терминалов
	const [selectedTerminals, setSelectedTerminals] = useState([]);

	// Обработка изменения состояния чекбоксов
	const handleCheckboxChange = (terminalId) => {
		clearErrors();
		setSelectedTerminals((prevSelected) => {
			if (prevSelected.includes(terminalId)) {
				// Удаляем терминал из выбранных, если он уже был выбран
				return prevSelected.filter((id) => id !== terminalId);
			} else {
				// Добавляем терминал в выбранные
				return [...prevSelected, terminalId];
			}
		});
	};

	// Инициализация терминалов из данных
	useEffect(() => {
		if (data && data.terminals) {
			setSelectedTerminals(data.terminals.map((terminal) => terminal.id));
		}
	}, [data]);

	useEffect(() => {
		setValue('terminals', selectedTerminals, { shouldTouch: true });
	}, [selectedTerminals, setValue]);

	const onSubmit = useCallback(
		(value) => {
			if (value.terminals.length === 0) return setError('terminals', { message: 'Поле обязательно' });
			if (data) {
				handleUpdate({ orgId: data.id, value });
			} else handleCreate({ user_type: 2, value });
		},
		[handleCreate, handleUpdate, data],
	);

	const { data: dataTerminals } = useGetTerminalsListQuery({});
	const [elements, setElements] = useState([]);

	useEffect(() => {
		if (Array.isArray(dataTerminals)) {
			const newElements = dataTerminals.map((item) => ({
				value: item.id,
				label: item.name,
			}));
			setElements(newElements);
		}
	}, [dataTerminals]);

	useEffect(() => {
		if (isCreateSuccess) navigate(PAGE_CLIENT_BY_ID(clientData?.organizations[0]?.id, 1));
	}, [isCreateSuccess, clientData]);

	return (
		<div style={{ display: 'flex', flexDirection: 'column', minHeight: '100%', flexGrow: 1, justifyContent: 'space-between' }}>
			<FormWrapper onSubmit={onSubmit} {...methods}>
				<div className={styles.containerFormTemplate}>
					<Controller
						name="full_name"
						control={control}
						render={({ field }) => (
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<InputText {...field} labelText="ФИО клиента" placeholder="ФИО клиента" />
								{errors?.full_name && (
									<Typography color="error" variant="caption">
										{errors?.full_name?.message}
									</Typography>
								)}
							</div>
						)}
					/>
					{!isEditMode && (
						<Controller
							name="password"
							control={control}
							render={({ field }) => (
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									<InputText {...field} labelText="Пароль клиента" placeholder="Пароль клиента" />
									{errors?.password && (
										<Typography color="error" variant="caption">
											{errors?.password?.message}
										</Typography>
									)}
								</div>
							)}
						/>
					)}
					<Controller
						name="organization_name"
						control={control}
						render={({ field }) => (
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<InputText {...field} labelText="Название" placeholder="Название" />
								{errors?.organization_name && (
									<Typography color="error" variant="caption">
										{errors?.organization_name?.message}
									</Typography>
								)}
							</div>
						)}
					/>
					<Controller
						name="email"
						control={control}
						render={({ field }) => (
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<InputText {...field} labelText="Email" placeholder="Email" />
								{errors?.email && (
									<Typography color="error" variant="caption">
										{errors?.email?.message}
									</Typography>
								)}
							</div>
						)}
					/>
					<Controller
						name="inn"
						control={control}
						render={({ field }) => (
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<InputText {...field} labelText="ИНН" placeholder="ИНН" />
								{errors?.inn && (
									<Typography color="error" variant="caption">
										{errors?.inn?.message}
									</Typography>
								)}
							</div>
						)}
					/>
					<Controller
						name="organization_address"
						control={control}
						render={({ field }) => (
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<InputText {...field} labelText="Адрес" placeholder="Адрес" />
								{errors?.organization_address && (
									<Typography color="error" variant="caption">
										{errors?.organization_address?.message}
									</Typography>
								)}
							</div>
						)}
					/>
					<Controller
						name="legalEntity"
						control={control}
						render={({ field }) => <LegalEntitiesInputAutocomplete {...field} />}
					/>
					<Controller
						name="assignee"
						control={control}
						render={({ field }) => (
							<AssigneesInputAutocomplete
								{...field}
								group={3}
								assigneesHistory={data?.assignees ? filterAssignees({ assignees: data?.assignees, type: 0 }) : []}
								terminal={selectedTerminals}
								labelText={'Ответственный менеджер'}
							/>
						)}
					/> 
					<div style={{ display: 'flex', gap: '20px', gridColumn: 'span 2' }}>
						<div style={{ marginTop: '8px' }}>
							<Typography variant="body1">Выберите терминалы</Typography>
							{errors?.terminals && (
								<Typography color="error" variant="caption">
									{errors?.terminals?.message}
								</Typography>
							)}
							<FormGroup>
								{elements.map((terminal) => (
									<FormControlLabel
										key={terminal.value}
										control={
											<Checkbox
												checked={selectedTerminals.includes(terminal.value)}
												onChange={() => handleCheckboxChange(terminal.value)}
												sx={{
													width: '24px',
													height: '24px',
													padding: '20px',
													color: '#5C53A7',
													'&.Mui-checked': {
														color: '#5C53A7',
													},
												}}
											/>
										}
										label={terminal.label}
									/>
								))}
							</FormGroup>
						</div>

						<div style={{ display: 'flex', flexDirection: 'column', gap: '12px', marginTop: '8px' }}>
							<Typography variant="body1">Дополнительные возможности</Typography>
							<Switcher
								onChecked={(checked) => setValue('isCan_confirm_repair', checked, { shouldTouch: true })}
								externalChecked={isCan_confirm_repair}
								right="Подтверждение выполнения ремонтных работ"
							/>
							<Switcher
								onChecked={(checked) => setValue('isCan_see_storage_price', checked, { shouldTouch: true })}
								externalChecked={isCan_see_storage_price}
								right="Расчет стоимости хранения в заявке"
							/>
						</div>
					</div>
				</div>
			</FormWrapper>
			<div style={{ justifyContent: 'flex-end', width: '100%', display: 'flex', justifyContent: 'space-between' }}>
				<Button variant="outlined" color="primary" size="medium" onClick={onBack}>
					Назад
				</Button>
				{data ? (
					<Button variant="contained" color="primary" size="medium" onClick={handleSubmit(onSubmit)}>
						Сохранить
					</Button>
				) : (
					<Button variant="contained" color="primary" size="medium" onClick={handleSubmit(onSubmit)}>
						Далее
					</Button>
				)}
			</div>
		</div>
	);
};
