import React, { useState, useEffect } from 'react';
import { Button, Typography } from '@mui/material';
import { Switcher } from '../../../../../../components';
import { useUpdateTerminalMutation } from '../../../../../../store/newApi/terminals/terminals';
import RequireCheckRights from '../../../../../../hoc/RequireCheckRights';
import { useHandleCreate } from '../hooks/useHandleCreate';
import { tranformErrorsToRender } from 'shared/api/tranformErrorsToRender';
import { useNotify } from 'shared/providers/AlertProvider/AlertProvider';

const defaultSwitches = [
	{ key: 'transport_hub', label: 'Автотранспорт' },
	{ key: 'railway_hub', label: 'Железнодорожный транспорт' },
	{ key: 'port_hub', label: 'Порт' },
	{ key: 'cargo_storage_module', label: 'Хранение грузов' },
	{ key: 'repacking_module', label: 'Перетарка грузов' },
	{ key: 'container_storage_module', label: 'Хранение контейнеров' },
	{ key: 'repair_module', label: 'Ремонт контейнеров' },
	{ key: 'bookkeeping_module', label: 'Бухгалтерия' },
];

export const TabTerminalModules = ({ data, onBack, values }) => {
	const { showNotificaton } = useNotify();
	const [updateTerminal] = useUpdateTerminalMutation();
	const [switchState, setSwitchState] = useState({});
	const [initialSwitchState, setInitialSwitchState] = useState({});
	const { handleCreate } = useHandleCreate({ values });

	useEffect(() => {
		// Устанавливаем начальное состояние свитчеров при получении данных
		const initialState = defaultSwitches.reduce((acc, { key }) => {
			acc[key] = data ? !!data[key] : false;
			return acc;
		}, {});
		setSwitchState(initialState);
		setInitialSwitchState(initialState);
	}, [data]);

	const handleSwitchChange = (name) => (newChecked) => {
		setSwitchState((prevState) => ({
			...prevState,
			[name]: newChecked,
		}));
	};

	const handleSave = () => {
		if (data) {
			updateTerminal({ terminalId: data?.id, ...switchState }).then((data) => {
				if (data.error) {
					showNotificaton({
						type: 'error',
						message: tranformErrorsToRender(data.error?.data, 'Не удалось изменить данные терминала'),
					});
				} else {
					showNotificaton({
						type: 'success',
						message: 'Данные терминала изменены',
					});
				}
			});
		} else {
			handleCreate({ ...switchState });
		}
	};

	return (
		<div style={{ display: 'flex', flexDirection: 'column', minHeight: '100%', flexGrow: 1, justifyContent: 'space-between' }}>
			<div style={{ display: 'flex', flexDirection: 'column', gap: '16px', margin: '16px' }}>
				<Typography variant="btnMedium">Активные модули на терминале</Typography>
				{defaultSwitches.map(({ key, label }) => (
					<div key={key} className="switcherItem">
						<Switcher externalChecked={switchState[key]} onChecked={handleSwitchChange(key)} right={<span>{label}</span>} />
					</div>
				))}
			</div>
			<div
				style={{
					justifyContent: 'space-between',
					width: '100%',
					display: 'flex',
					padding: '5px 11px',
					position: 'sticky',
					bottom: 0,
					background: '#fff',
					zIndex: '1000',
					borderTop: '1px solid #ddd',
					maxHeight: '50px',
				}}
			>
				<Button variant="outlined" color="primary" size="medium" onClick={onBack}>
					Назад
				</Button>
				<RequireCheckRights path="terminals" type="change">
					<Button variant="contained" color="primary" size="medium" onClick={handleSave}>
						Сохранить
					</Button>
				</RequireCheckRights>
			</div>
		</div>
	);
};
