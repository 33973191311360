import { filterTouchedFields } from 'newComponents/Form/utils/filterTouchedFields';
import { useCallback, useEffect } from 'react';
import { tranformErrorsToRender } from 'shared/api/tranformErrorsToRender';
import { useNotify } from 'shared/providers/AlertProvider/AlertProvider';
import { useUpdateLegalEntityMutation } from 'store/newApi/legalEntities/legalEntities';

export const useHandleUpdate = ({ legalEntityId, ...methods }) => {
	const { showNotificaton } = useNotify();
	const {
		formState: { touchedFields },
	} = methods;
	const [update, { isSuccess: isUpdateSuccess, isLoading: isUpdateLoading, reset: resetUpdate }] = useUpdateLegalEntityMutation(); 

	const handleUpdate = useCallback(
		(value) => {
			const touchedFieldValues = filterTouchedFields(value, touchedFields);

			const payload = {
				full_name_organization: touchedFieldValues?.full_name_organization,
				eng_full_name_organization: touchedFieldValues?.eng_full_name_organization,
				inn: touchedFieldValues?.inn,
				kpp: touchedFieldValues?.kpp,
				ogrn: touchedFieldValues?.ogrn,
				legal_address: touchedFieldValues?.legal_address,
				actual_address: touchedFieldValues?.actual_address,
				contact_number: touchedFieldValues?.contact_number,
				direct_name: touchedFieldValues?.direct_name,
			};

			update({
				id: legalEntityId,
				...payload,
			}).then((data) => {
				if (data.error) {
					showNotificaton({
						type: 'error',
						message: tranformErrorsToRender(data.error?.data, 'Не удалось обновить данные'),
					});
				} else {
					resetUpdate();
					showNotificaton({
						type: 'success',
						message: 'Данные успешно обновлены',
					});
				}
			});
		},
		[update, touchedFields],
	);

	return {
		handleUpdate,
		isUpdateSuccess,
		isUpdateLoading,
	};
};
