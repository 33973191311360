import { Button, useMediaQuery } from '@mui/material';
import styles from './AccountEmplOneTerminal.module.css';
import Header from '../../../../../newComponents/Header/Header';
import { useNavigate, useParams } from 'react-router-dom';
import { TabTerminalInfo } from './components/TabTerminalInfo';
import { TabTerminalModules } from './components/TabTerminalModules';
import { useEffect, useState } from 'react';
import { useLazyGetTerminalDetailQuery } from '../../../../../store/newApi/terminals/terminals';
import { TERMINALS_LIST_URL } from 'shared/terminals/pageUrls';

export const AccountEmplOneTerminal = () => {
	const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('desktop'));
	const navigate = useNavigate();
	const [currentStep, setCurrentStep] = useState(0);
	const [values, setValues] = useState({});
	const { terminalId } = useParams();
	const [getTerminalDetail, { data: dataTerminalDetail }] = useLazyGetTerminalDetailQuery();

	useEffect(() => {
		if (terminalId) {
			getTerminalDetail(terminalId);
		}
	}, [terminalId]);

	const handleGoBackList = () => {
		navigate(TERMINALS_LIST_URL());
	};

	const renderStep = () => {
		switch (currentStep) {
			case 0:
				return (
					<TabTerminalInfo
						onBack={handleGoBackList}
						setCurrentStep={setCurrentStep}
						values={values}
						setValues={setValues}
					/>
				);
			case 1:
				return <TabTerminalModules data={dataTerminalDetail} onBack={handleGoBackList} values={values} />;
			default:
				return null;
		}
	};

	return (
		<div style={{ height: '100%', display: 'flex', flexDirection: 'column', width: '100%' }}>
			<Header text={dataTerminalDetail ? `Терминал ${dataTerminalDetail.name}` : 'Создать терминал'} />
			<div className={styles.content}>
				<div className={styles.tabs}>
					<Button
						variant="contained"
						color={currentStep === 0 ? 'secondary' : 'primary'}
						size={isDesktop ? 'medium' : 'small'}
						onClick={() => setCurrentStep(0)}
					>
						Общее
					</Button>
					<Button
						variant="contained"
						color={currentStep === 1 ? 'secondary' : 'primary'}
						size={isDesktop ? 'medium' : 'small'}
						onClick={() => setCurrentStep(1)}
						disabled={!terminalId && Object.keys(values).length == 0}
					>
						Модули
					</Button>
				</div>
				<div className={styles.steps}>{renderStep()}</div>
			</div>
		</div>
	);
};
