import { TERMINAL_ROUTE_PERMISSIONS } from 'pages/Account/Layout/Menu/MenuDesktop/MenuConfig';
import { modelPathMatch } from './modelPathMatch';

export function handleSearchTypePermission(terminals, path, type) {
	if (path == 'main') {
		return true;
	} else {
		// Сопоставить модель и путь
		const model = modelPathMatch.find((item) => item.path === path)?.model;

		if (!model) return false;

		// Проверяем что модуль включен хотя бы на одном доступном терминале
		const terminalModuleAvailable = TERMINAL_ROUTE_PERMISSIONS[path] && terminals.some(t => Object.hasOwn(t, TERMINAL_ROUTE_PERMISSIONS[path]))
			? terminals.some(t =>  t[TERMINAL_ROUTE_PERMISSIONS[path]])
			: true;

		// Проверяем что у юзера есть доступ к модулю
		const userPermissions = (terminals || [])?.some(
			(terminal) =>
				terminal.permissions ?
				terminal.permissions.some(
					(permission) => permission.model.toLowerCase() === model.toLowerCase() && permission.permission_type.includes(type),
				) : true
		);

		return terminalModuleAvailable && userPermissions;
	}
}
