import { useCallback, useEffect, useState } from 'react';
import { useUpdateOrderMutation } from '../../../../../../../store/newApi/orders/orders';
import InputText from '../../../../../../../newComponents/Inputs/InputText/InputText';
import { Button } from '@mui/material';
import { PageFooterActions } from '../../../../../../../shared/ui/PageFooterActions/PageFooterActions';
import { useNotify } from 'shared/providers/AlertProvider/AlertProvider';
import { tranformErrorsToRender } from 'shared/api/tranformErrorsToRender';

export const OrderCommentStep = ({ onCancel, onBack, defaultComment, orderId, terminalId, disabledEdit, refetchOrder }) => {
	const [updateOrder, { data, isSuccess, reset }] = useUpdateOrderMutation();
	const [comment, setComment] = useState('');
	const { showNotificaton } = useNotify();

	const handleFinish = useCallback(() => {
		if (orderId && terminalId)
			updateOrder({
				terminalId,
				orderId,
				comment,
			}).then((data) => {
                // @ts-ignore
				if (data.error) {
					showNotificaton({
						type: 'error', 
                        // @ts-ignore
						message: tranformErrorsToRender(data.error?.data, 'Не удалось обновить заявку'),
					});
				} else {
					reset();
                    refetchOrder();
					showNotificaton({
						type: 'success',
						message: 'Заявка успешно обновлена',
					});
				}
			});
	}, [updateOrder, comment, orderId, terminalId]);

	useEffect(() => {
		if (defaultComment) setComment(defaultComment);
	}, [defaultComment]);

	return (
		<div style={{ display: 'flex', flexDirection: 'column', gap: '5px', justifyContent: 'space-between', flexGrow: 1 }}>
			<div style={{ padding: '10px' }}>
				{/* @ts-ignore */}
				<InputText
					defaultValue={defaultComment}
					value={comment}
					placeholder="Введите текст"
					height="200px"
					onChange={(e) => setComment(e.target.value)}
				/>
			</div>
			<PageFooterActions
				leftBtns={[
					{
						text: 'Отмена',
						variant: 'outlined',
						color: 'primary',
						size: 'medium',
						onClick: onCancel,
					},
				]}
				rightBtns={
					!disabledEdit
						? [
								{
									text: 'Назад',
									variant: 'contained',
									color: 'primary',
									size: 'medium',
									onClick: onBack,
								},
								{
									text: 'Сохранить',
									variant: 'contained',
									color: 'primary',
									size: 'medium',
									onClick: handleFinish,
								},
							]
						: [
								{
									text: 'Назад',
									variant: 'contained',
									color: 'primary',
									size: 'medium',
									onClick: onBack,
								},
							]
				}
			/>
		</div>
	);
};
