import { useEffect } from 'react';

export const useInitFormState = ({ data, ...methods }) => {
	const { setValue } = methods;

	useEffect(() => {
		if (data) {
			if (data?.name) setValue('name', data.name);
			if (data?.address) setValue('address', data.address);
			if (data?.max_teu) setValue('max_teu', data.max_teu);
			if (data?.contact_number) setValue('contact_number', data.contact_number);

			if (data?.schedule) {
				Object.entries(data.schedule).forEach(([day, times]) => {
					setValue(`schedule.${day}.enabled`, false);
					setValue(`schedule.${day}.aroundClock`, false);
					if (times?.start === '24' || times?.end === '24') {
						setValue(`schedule.${day}.enabled`, true);
						setValue(`schedule.${day}.aroundClock`, true);
					} else if (times?.start === '-' || times?.end === '-') {
						setValue(`schedule.${day}.enabled`, false);
						setValue(`schedule.${day}.aroundClock`, false);
					} else {
						if (times?.start) {
							setValue(`schedule.${day}.enabled`, true);
							setValue(`schedule.${day}.start`, times.start);
						}
						if (times?.end) {
							setValue(`schedule.${day}.enabled`, true);
							setValue(`schedule.${day}.end`, times.end);
						}
					}
				});
			}
		}
	}, [data, setValue]);
};
