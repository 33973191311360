import { useCallback, useEffect } from 'react';
import { tranformErrorsToRender } from 'shared/api/tranformErrorsToRender';
import { useNotify } from 'shared/providers/AlertProvider/AlertProvider';
import { useCreateExtraservicesMutation } from 'store/newApi/extraservices/extraservices';

export const useHandleCreate = ({ handleClose, refetchData, ...methods }) => {
	const { showNotificaton } = useNotify();
	const { reset: resetForm } = methods;
	const [
		createExtraservices,
		{ isSuccess: isCreateSuccess, isError: isCreateError, isLoading: isCreateLoading, reset: resetCreateExtraservicesMutation },
	] = useCreateExtraservicesMutation();

	useEffect(() => {
		if (isCreateSuccess) {
			handleClose();
			resetCreateExtraservicesMutation();
			resetForm();
			refetchData();
		}

		return () => {
			resetForm();
		};
	}, [isCreateSuccess, handleClose, refetchData, resetCreateExtraservicesMutation, resetForm]);

	const handleCreate = useCallback(
		(value) => {
			const isServiceSelected = value.service && typeof value.service === 'object';
			const payload = {
				container: value.container,
				quantity: value.quantity && Number(value.quantity),
				price_per_unit: value.price_per_unit && Number(value.price_per_unit),
				...(isServiceSelected ? { service: value.service.id } : { extra_service_name: value.extra_service_name || value.service }),
			};

			createExtraservices({
				...payload,
			}).then((data) => {
				if (data.error) {
					showNotificaton({
						type: 'error',
						message: tranformErrorsToRender(data.error?.data, 'Не удалось создать услугу'),
					});
				} else {
					handleClose();
					resetCreateExtraservicesMutation();
					resetForm();
					refetchData();
					showNotificaton({
						type: 'success',
						message: 'Услуга успешно создана',
					});
				}
			});
		},
		[createExtraservices],
	);

	return {
		handleCreate,
		isCreateSuccess,
		isCreateLoading,
		isCreateError,
	};
};
