import api from '../../api.js';
import { buildQueryString } from '../../../shared/ui/Filters/utils.js';
import {
	CreateExtraservicesPayload,
	CreateExtraservicesResponse,
	GetExtraservicesListParams,
	GetExtraservicesListResponse,
	UpdateExtraservicesPayload,
	UpdateExtraservicesResponse,
} from 'shared/extraservices/types.js';

export const extraservices = api.injectEndpoints({
	endpoints: (builder) => ({
		getExtraservicesList: builder.query<GetExtraservicesListResponse, GetExtraservicesListParams>({
			query: (params) => {
				let queryParams = { ...params };

				delete queryParams.uniq;

				return {
					url: `/api/service-order/extra-services/orders/list/?${buildQueryString(queryParams)}`,
					method: 'GET',
				};
			},
		}),
		createExtraservices: builder.mutation<CreateExtraservicesResponse, CreateExtraservicesPayload>({
			query: ({ ...payload }) => {
				return {
					url: `/api/service-order/extra-services/orders/create/`,
					method: 'POST',
					body: { ...payload },
				};
			},
		}),
		updateExtraservices: builder.mutation<UpdateExtraservicesResponse, UpdateExtraservicesPayload>({
			query: ({ ...payload }) => {
				return {
					url: `/api/service-order/extra-services/orders/batch-update/`,
					method: 'PUT',
					body: { ...payload },
				};
			},
		}),
	}),
});

export const {
	useGetExtraservicesListQuery,
	useLazyGetExtraservicesListQuery,
	useCreateExtraservicesMutation,
	useUpdateExtraservicesMutation,
} = extraservices;
