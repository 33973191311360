import { useEffect } from 'react';
import { handleFindWorker } from '../../../../shared/empls/utils'; 

export const useInitFormState = ({ data, isCreate, ...methods }) => {
	const { setValue } = methods;

	useEffect(() => {
		if (data) {
			setValue('container_number', data?.container?.container_number);
			setValue('vehicle_driver', data?.vehicle_driver);
			setValue('vehicle', data?.vehicle);
			setValue('vehicle_trailer_number', data?.vehicle_trailer_number);
			setValue('seal_number', data?.seal_number);
			if (data?.assignees) {
				setValue('assignee', handleFindWorker({ assignees: data?.assignees, type: 0, item: 'worker' }));
			}
			if (data?.eta_checkin) {
				setValue('eta_checkin', data?.eta_checkin);
			}
		}
	}, [data, setValue]);
};
