export const tableComponent = {
	header: {
		backgroundColor: '#F1F3F9',
		color: '#343C6A',

		'div > span > span > svg.MuiTableSortLabel-icon': {
			opacity: '1 !important',
			color: '#343C6A !important',
			fill: '#343C6A !important',
		},

		'div span span.MuiTableSortLabel-root': {
			opacity: '1 !important',
		},
	},

	topToolbar: {
		minHeight: '0',
	},

	tablePaper: {
		backgroundColor: '#F1F3F9',
		borderRadius: '8px',
		boxShadow: 'none',

		'> .MuiBox-root:first-of-type': {
			display: 'none',
		},
	},

	tableContainer: {
		maxHeight: 'calc(100vh - 188px)',
		minHeight: 'calc(100vh - 188px)',
	},

	footer: {
		minHeight: 'none',
		backgroundColor: '#F1F3F9',

		'div:nth-of-type(2)': {
			padding: '0px',
		},
	},
};

export const toolbarAlertBanner = (isTablet) => ({
	position: 'relative',
	'> div': {
		maxWidth: '100% !important',
		position: 'absolute',
		right: '4px',
		top: isTablet ? '0' : '11px',
		color: '#343C6A',
		fontSize: '12px',

		button: {
			display: 'none',
		},
	},
});
