import * as z from 'zod';

export const createSchema = z.object({
	name: z.string({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
	address: z.string().optional(),
	max_teu: z.number({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
	contact_number: z.string().optional(),
	schedule: z
		.object({
			Пн: z.object({
				start: z.string().optional(),
				end: z.string().optional(),
				aroundClock: z.boolean().optional(),
				enabled: z.boolean().optional(),
			}),
			Вт: z.object({
				start: z.string().optional(),
				end: z.string().optional(),
				aroundClock: z.boolean().optional(),
				enabled: z.boolean().optional(),
			}),
			Ср: z.object({
				start: z.string().optional(),
				end: z.string().optional(),
				aroundClock: z.boolean().optional(),
				enabled: z.boolean().optional(),
			}),
			Чт: z.object({
				start: z.string().optional(),
				end: z.string().optional(),
				aroundClock: z.boolean().optional(),
				enabled: z.boolean().optional(),
			}),
			Пт: z.object({
				start: z.string().optional(),
				end: z.string().optional(),
				aroundClock: z.boolean().optional(),
				enabled: z.boolean().optional(),
			}),
			Сб: z.object({
				start: z.string().optional(),
				end: z.string().optional(),
				aroundClock: z.boolean().optional(),
				enabled: z.boolean().optional(),
			}),
			Вс: z.object({
				start: z.string().optional(),
				end: z.string().optional(),
				aroundClock: z.boolean().optional(),
				enabled: z.boolean().optional(),
			}),
		})
		.optional(),
});

export const updateSchema = z.object({
	name: z.string({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
	address: z.string().optional(),
	max_teu: z.number({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
	contact_number: z.string().optional(),
	schedule: z
		.object({
			Пн: z.object({
				start: z.string().optional(),
				end: z.string().optional(),
				aroundClock: z.boolean().optional(),
				enabled: z.boolean().optional(),
			}),
			Вт: z.object({
				start: z.string().optional(),
				end: z.string().optional(),
				aroundClock: z.boolean().optional(),
				enabled: z.boolean().optional(),
			}),
			Ср: z.object({
				start: z.string().optional(),
				end: z.string().optional(),
				aroundClock: z.boolean().optional(),
				enabled: z.boolean().optional(),
			}),
			Чт: z.object({
				start: z.string().optional(),
				end: z.string().optional(),
				aroundClock: z.boolean().optional(),
				enabled: z.boolean().optional(),
			}),
			Пт: z.object({
				start: z.string().optional(),
				end: z.string().optional(),
				aroundClock: z.boolean().optional(),
				enabled: z.boolean().optional(),
			}),
			Сб: z.object({
				start: z.string().optional(),
				end: z.string().optional(),
				aroundClock: z.boolean().optional(),
				enabled: z.boolean().optional(),
			}),
			Вс: z.object({
				start: z.string().optional(),
				end: z.string().optional(),
				aroundClock: z.boolean().optional(),
				enabled: z.boolean().optional(),
			}),
		})
		.optional(),
});

export const defaultValuesForm = {
	name: '',
	address: '',
	max_teu: undefined,
	contact_number: '',
	schedule: {
		Пн: {
			start: '',
			end: '',
			aroundClock: true,
			enabled: true,
		},
		Вт: {
			start: '',
			end: '',
			aroundClock: true,
			enabled: true,
		},
		Ср: {
			start: '',
			end: '',
			aroundClock: true,
			enabled: true,
		},
		Чт: {
			start: '',
			end: '',
			aroundClock: true,
			enabled: true,
		},
		Пт: {
			start: '',
			end: '',
			aroundClock: true,
			enabled: true,
		},
		Сб: {
			start: '',
			end: '',
			aroundClock: true,
			enabled: true,
		},
		Вс: {
			start: '',
			end: '',
			aroundClock: true,
			enabled: true,
		},
	},
};
