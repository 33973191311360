import { useMemo, useState } from 'react';
import { UniversalButton } from '../../../../components';
import styles from '../AccountEmpl.module.scss';
import Header from '../../../../newComponents/Header/Header';
import { useDispatch, useSelector } from 'react-redux';
import TableComponent from '../../../../newComponents/TableComponent/TableComponent';
import { useLazyGetContainersListQuery } from '../../../../store/newApi/containers/containers';
import Cookies from 'js-cookie';
import { LINK_CONTAINER_BY_ID } from 'shared/containers/pageUrls';
import { LINK_CLIENT_BY_ID } from 'shared/clients/pageUrls';

function AccountEmplSummary() {
	const userType = Cookies.get('userType');
	const dispatch = useDispatch();

	const [fetchContainers, { data, isLoading, isError }] = useLazyGetContainersListQuery();

	// Выбор строк
	const [rowSelection, setRowSelection] = useState({});

	// Вспомогательная функция по поиску по типам заявок
	function findOrder(array, key, value) {
		const foundObject = array.find((obj) => obj[key] === value);
		return foundObject ? foundObject : '';
	}

	// колонки
	const columnsList = useMemo(() => {
		const baseColumns = [
			{
				accessorFn: (row) => row.status_display,
				id: 'status',
				header: 'Статус',
				size: 192,
			},
			{
				accessorFn: (row) => row.line_display,
				id: 'line',
				header: 'Линия',
				size: 232,
			},
			{
				accessorFn: (row) => {
					const url = LINK_CONTAINER_BY_ID(row.id);
					return (
						<a href={url} target="_blank" rel="noopener noreferrer nofollow">
							{row.container_number}
						</a>
					);
				},
				id: 'container_number',
				header: '№ контейнера',
				size: 230,
			},
			{
				accessorFn: (row) => row.size_display,
				id: 'size',
				header: 'Размер',
				size: 200,
			},
			{
				accessorFn: (row) => row.type_display,
				id: 'type',
				header: 'Тип',
				size: 180,
			},
			{
				accessorFn: (row) => row.booking,
				id: 'booking',
				header: 'Букинг',
				size: 280,
			},
			{
				accessorFn: (row) => (row.is_filled ? 'Да' : '-'),
				id: 'is_filled',
				header: 'Заполнен',
				size: 280,
			},
			{
				accessorFn: (row) => (row.is_dangerous ? 'Да' : '-'),
				id: 'is_dangerous',
				header: 'Опасный груз',
				size: 280,
			},
			{
				accessorFn: (row) => (row.is_damaged ? 'Да' : '-'),
				id: 'is_damaged',
				header: 'Поврежден',
				size: 280,
			},
			{
				accessorFn: (row) => row.entrypass[0]?.terminal_display,
				id: 'terminal',
				header: 'Терминал',
				size: 230,
			},

			{
				accessorFn: (row) => findOrder(row.entrypass, 'order_type_display', 'Заявка на ввоз').gatein_number,
				id: 'order_in',
				header: '№ Заявки на ввоз',
				size: 250,
			},

			{
				accessorFn: (row) => findOrder(row.entrypass, 'order_type_display', 'Заявка на ввоз').status_display,
				id: 'entrypass_in_status',
				header: 'Статус пропуска (ввоз)',
				size: 320,
			},

			{
				accessorFn: (row) => findOrder(row.entrypass, 'order_type_display', 'Заявка на ввоз').vehicle_display,
				id: 'vehicle_in',
				header: 'Номер A/М (ввоз)',
				size: 230,
			},

			{
				accessorFn: (row) => findOrder(row.entrypass, 'order_type_display', 'Заявка на ввоз').vehicle_driver,
				id: 'driver_in',
				header: 'Водитель (ввоз)',
				size: 230,
			},

			{
				accessorFn: (row) => findOrder(row.entrypass, 'order_type_display', 'Заявка на ввоз').checkin_at,
				id: 'checkin_at',
				header: 'Дата ввоза',
				size: 340,
			},
			{
				accessorFn: (row) => findOrder(row.entrypass, 'order_type_display', 'Заявка на вывоз').gateout_number,
				id: 'order_out',
				header: '№ Заявки на вывоз',
				size: 250,
			},

			{
				accessorFn: (row) => findOrder(row.entrypass, 'order_type_display', 'Заявка на вывоз').status_display,
				id: 'entrypass_out_status',
				header: 'Статус пропуска (вывоз)',
				size: 320,
			},
			{
				accessorFn: (row) => findOrder(row.entrypass, 'order_type_display', 'Заявка на вывоз').vehicle_display,
				id: 'vehicle_out',
				header: 'Номер А/М (вывоз)',
				size: 260,
			},
			{
				accessorFn: (row) => findOrder(row.entrypass, 'order_type_display', 'Заявка на вывоз').vehicle_driver,
				id: 'driver_out',
				header: 'Водитель (вывоз)',
				size: 230,
			},
			{
				accessorFn: (row) => findOrder(row.entrypass, 'order_type_display', 'Заявка на вывоз').chekin_at,
				id: 'checkout_at',
				header: 'Дата вывоза',
				size: 340,
			},
		];

		if (userType == 1) {
			baseColumns.push({
				accessorFn: (row) => {
					const url = LINK_CLIENT_BY_ID(row.client);
					return (
						<a href={url} target="_blank" rel="noopener noreferrer nofollow">
							{row.client_display}
						</a>
					);
				},
				id: 'org',
				header: 'Клиент',
				size: 280,
				sortDescFirst: false,
			});
		}

		return baseColumns;
	}, [userType]);

	// расположение колонок
	const columnOrdering = [
		'mrt-row-select',
		'status',
		'line',
		'container_number',
		'size',
		'type',
		'org',
		'booking',
		'is_filled',
		'is_dangerous',
		'is_damaged',
		'terminal',
		'order_in',
		'entrypass_in_status',
		'vehicle_in',
		'driver_in',
		'checkin_at',
		'order_out',
		'entrypass_out_status',
		'vehicle_out',
		'driver_out',
		'checkout_at',
	];

	return (
		<div className={styles.account__wrap}>
			<Header text={'Сводная таблица'} />
			<div className={styles.account__container}>
				<div className={styles.account__stack}>
					<TableComponent
						rowSelection={rowSelection}
						setRowSelection={setRowSelection}
						fetchData={fetchContainers}
						isLoading={isLoading}
						isError={isError}
						data={data}
						columnsList={columnsList}
						columnOrdering={columnOrdering}
					/>
				</div>
			</div>
		</div>
	);
}

export default AccountEmplSummary;
