import api from '../../api.js';
import { buildQueryString } from '../../../shared/ui/Filters/utils.js';

export const entries = api.injectEndpoints({
	endpoints: (builder) => ({
		getEntriesList: builder.query({
			query: (params) => {
				let queryParams = { ...params };
				const sortParam = params.sorting?.length
					? params.sorting
							.map(({ id, desc }) => {
								return desc ? `-${id}` : id;
							})
							.join(',')
					: null;

				if (sortParam) {
					queryParams.ordering = sortParam;
				}

				delete queryParams.uniq;
				delete queryParams.sorting;

				return {
					url: `/api/entries/?${buildQueryString(queryParams)}`,
					method: 'GET',
				};
			},
		}),
		getEntriesListComplete: builder.query({
			query: (params) => {
				let queryParams = { ...params };
				const sortParam = params.sorting?.length
					? params.sorting
							.map(({ id, desc }) => {
								return desc ? `-${id}` : id;
							})
							.join(',')
					: null;

				if (sortParam) {
					queryParams.ordering = sortParam;
				}

				delete queryParams.uniq;
				delete queryParams.sorting;

				return {
					url: `/api/entries/?status=1&${buildQueryString(queryParams)}`,
					method: 'GET',
				};
			},
		}),
		getEntry: builder.query({
			query: ({ terminalId, orderId, entryId }) => {
				return {
					url: `/api/entries/${terminalId}/${orderId}/${entryId}/`,
					method: 'GET',
				};
			},
		}),
		updateEntry: builder.mutation({
			query: ({ terminalId, orderId, ...payload }) => {
				return {
					url: `/api/entries/${terminalId}/${orderId}/`,
					method: 'PUT',
					body: [
						{
							...payload,
						},
					],
				};
			},
		}),
	}),
});

export const { useGetEntriesListQuery, useLazyGetEntriesListQuery, useLazyGetEntriesListCompleteQuery, useLazyGetEntryQuery, useGetEntryQuery, useUpdateEntryMutation } =
	entries;
