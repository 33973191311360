import { useCallback, useEffect, useMemo } from 'react';
import { filterTouchedFields } from '../../utils/filterTouchedFields';
import { useUpdateRepairTariffGroupMutation } from '../../../../store/newApi/service/service';
import { useSelector } from 'react-redux';

export const useHandleUpdate = ({ entityIds, refetchData, handleClose, ...methods }) => {
	const filters = useSelector((state) => state['repairTasksRtk']?.filter) ?? {};
	const isSingleUpdate = useMemo(() => entityIds.length === 1, [entityIds]);
	const {
		formState: { touchedFields },
		reset: resetForm,
	} = methods;
	const [updateRepairTariff, { isSuccess: isUpdateSuccess, isLoading: isUpdateLoading, reset: resetUpdateRepairTariffMutation }] =
		useUpdateRepairTariffGroupMutation();

	useEffect(() => {
		if (isUpdateSuccess) {
			handleClose();
			resetUpdateRepairTariffMutation();
			resetForm();
			refetchData(filters);
			// TODO: Вызвать нотификашку
		}

		return () => {
			resetForm();
		};
	}, [isUpdateSuccess, handleClose, resetUpdateRepairTariffMutation, resetForm, refetchData]);

	const handleUpdate = useCallback(
		(value) => {
			const touchedFieldValues = filterTouchedFields(value, touchedFields);
			const payload = {
				tasks: entityIds,
				terminal: touchedFieldValues.terminal,
				name: touchedFieldValues.name,
				repair_component: touchedFieldValues.repair_component,
				labor_rate_per_hour: touchedFieldValues.labor_rate_per_hour && parseFloat(touchedFieldValues.labor_rate_per_hour),
				unit: touchedFieldValues.unit,
				size_step: touchedFieldValues.size_step && parseFloat(touchedFieldValues.size_step),
				hour_first_step: touchedFieldValues.hour_first_step && parseFloat(touchedFieldValues.hour_first_step),
				material_first_step: touchedFieldValues.material_first_step && parseFloat(touchedFieldValues.material_first_step),
				size_next_step: touchedFieldValues.size_next_step && parseFloat(touchedFieldValues.size_next_step),
				hour_next_step: touchedFieldValues.hour_next_step && parseFloat(touchedFieldValues.hour_next_step),
				material_next_step: touchedFieldValues.material_next_step && parseFloat(touchedFieldValues.material_next_step),
			};
			if (filters?.client_ids) {
				payload['client'] = filters?.client_ids;
			} 
			updateRepairTariff(payload);
		},
		[entityIds, updateRepairTariff, touchedFields, isSingleUpdate],
	);

	return {
		handleUpdate,
		isUpdateSuccess,
		isUpdateLoading,
	};
};
