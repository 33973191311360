import { useCallback, useEffect } from 'react';
import { useUpdateOrgMutation } from '../../../../../../../../store/newApi/organizations/organizations';
import { filterTouchedFields } from '../../../../../../../../newComponents/Form/utils/filterTouchedFields';
import { useNotify } from 'shared/providers/AlertProvider/AlertProvider';

export const useHandleUpdate = ({ refetchData, ...methods }) => {
	const {
		formState: { touchedFields },
		reset: resetForm,
	} = methods;
	const [updateClient, { isSuccess: isUpdateSuccess, isLoading: isUpdateLoading, reset: resetUpdate }] = useUpdateOrgMutation();
	const { showNotificaton } = useNotify();

	useEffect(() => {
		if (isUpdateSuccess) {
			refetchData();
			resetUpdate();
		} 
	}, [isUpdateSuccess, refetchData, resetUpdate]);

	const handleUpdate = useCallback(
		({ orgId, value }) => {
			const touchedFieldValues = filterTouchedFields(value, touchedFields);
			const payload = {
				full_name: touchedFieldValues.full_name,
				password: touchedFieldValues.password,
				organization_name: touchedFieldValues.organization_name,
				contact_email: touchedFieldValues.email,
				inn: touchedFieldValues.inn,
				organization_address: touchedFieldValues.organization_address,
				can_confirm_repair: touchedFieldValues.isCan_confirm_repair,
				can_see_storage_price: touchedFieldValues.isCan_see_storage_price,
				terminals: touchedFieldValues.terminals, 
				legal_entities: touchedFieldValues.legalEntity,
			};

			if (touchedFieldValues.assignee) {
				payload['set_assignee'] = {
					worker: touchedFieldValues.assignee,
					type: 0,
				}
			} 

			updateClient({
				orgId: orgId,
				...payload,
			})
				.then((data) => {
					if (!!data.error) {
						showNotificaton({ type: 'error', message: 'Не удалось обновить клиента' })
					} else {
						showNotificaton({ type: 'success', message: 'Клиент успешно обновлен' })
					}
				}) 
		},
		[updateClient, touchedFields],
	);

	return {
		handleUpdate,
		isUpdateSuccess,
		isUpdateLoading,
	};
};
