import { Controller, useForm } from 'react-hook-form';
import { useCallback, useMemo } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { FormWrapper } from '../FormWrapper';
import InputText from '../../Inputs/InputText/InputText';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useInitFormState } from './hooks/useInitFormState';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useHandleUpdate } from './hooks/useHandleUpdate';
import { useHandleCreate } from './hooks/useHandleCreate';
import { entriesForm } from './EntriesForm.style';
import { useNavigate, useParams } from 'react-router-dom';
import { ContainerInputAutocomplete } from '../Fields/ContainerInputAutocomplete';
import { useLazyGetContainerByIdQuery } from '../../../store/newApi/containers/containers';
import { isEmpl } from '../../../shared/auth/isEmpl';
import { isClient } from '../../../shared/auth/isClient';
import { VehicleInputAutocomplete } from '../Fields/VenicleInputAutoComplete/VehicleInputAutocomplete';
import { PAGE_ENTRIES } from '../../../shared/entries/pageUrls';
import { AssigneesInputAutocomplete } from '../Fields/AssigneesInputAutocomplete';
import { filterAssignees, handleFindWorker } from '../../../shared/empls/utils';
import { useGetEntryQuery } from '../../../store/newApi/entries/entries';
import { DateInput } from 'newComponents/Inputs/DateInput/DateInput';

const createSchema = z.object({
	container_number: z.number({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
	vehicle_driver: z.string().optional(),
	vehicle: z.any().optional(),
	vehicle_trailer_number: z.any().optional(),
	seal_number: z.any().optional(),
});

const updateSchema = z.object({
	container_number: z.string().optional(),
	vehicle_driver: z.any().optional(),
	vehicle: z.any().optional(),
	vehicle_trailer_number: z.any().optional(),
	seal_number: z.any().optional(),
	eta_checkin: z.any().optional(),
	assignee: z.any().optional(),
});

export const EntriesForm = ({ refetchData, isCreate = false, entry }) => {
	const resolver = useMemo(() => (isCreate ? zodResolver(createSchema) : zodResolver(updateSchema)), [isCreate]);
	const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('desktop'));
	const navigate = useNavigate();
	let { terminalId, orderId, entryId } = useParams();
	const { data } = useGetEntryQuery(
		{ terminalId, orderId, entryId },
		{
			skip: isCreate,
			refetchOnMountOrArgChange: true,
		},
	);

	const methods = useForm({
		defaultValues: {
			container_number: undefined,
			vehicle_driver: null,
			vehicle: null,
			vehicle_trailer_number: null,
			seal_number: null,
			assignee: '',
			eta_checkin: undefined,
		},
		resolver: resolver,
	});

	const { handleUpdate, isUpdateLoading } = useHandleUpdate({ ...methods, refetchData });
	const { handleCreate, isCreateLoading } = useHandleCreate({ ...methods });

	const {
		handleSubmit,
		control,
		setValue,
		formState: { errors },
	} = methods;

	const onSubmit = useCallback(
		(value) => {
			if (isCreate) {
				handleCreate(value);
			} else {
				handleUpdate(value);
			}
		},
		[handleCreate, handleUpdate, isCreate],
	);

	useInitFormState({ data, isCreate, ...methods });

	const [fetchContainer, { container, loading: loadingClients }] = useLazyGetContainerByIdQuery({
		selectFromResult: ({ data }) => ({
			container: data,
		}),
	});

	return (
		<FormWrapper onSubmit={onSubmit} {...methods}>
			<Grid
				container
				sx={{
					background: '#fff',
					padding: '12px',
					borderRadius: '12px',
					width: '100%',
					display: 'flex',
					flexDirection: 'row',
					gap: '8px',
				}}
			>
				<Grid item sx={{ width: isDesktop ? 'calc(50% - 4px)' : '100%' }}>
					<Controller
						name="container_number"
						control={control}
						render={({ field }) =>
							isCreate ? (
								<ContainerInputAutocomplete {...field} labelText="Номер контейнера" />
							) : (
								<InputText {...field} disabled placeholder="Номер контейнера" labelText="Номер контейнера" />
							)
						}
					/>
				</Grid>

				<Grid item sx={{ width: isDesktop ? 'calc(50% - 4px)' : '100%' }}>
					<Controller
						name="vehicle_driver"
						control={control}
						render={({ field }) => (
							<InputText
								{...field}
								disabled={!isCreate && data?.status !== 0}
								errorMessage={errors?.vehicle_driver?.message}
								placeholder="Водитель"
								labelText="Водитель"
							/>
						)}
					/>
				</Grid>
				<Grid item sx={{ width: isDesktop ? 'calc(50% - 4px)' : '100%' }}>
					<Controller
						name="vehicle_trailer_number"
						control={control}
						render={({ field }) => (
							<InputText
								{...field}
								disabled={!isCreate && data?.status !== 0}
								errorMessage={errors?.vehicle_trailer_number?.message}
								placeholder="Номер прицепа"
								labelText="Номер прицепа"
							/>
						)}
					/>
				</Grid>
				<Grid item sx={{ width: isDesktop ? 'calc(50% - 4px)' : '100%' }}>
					<Controller
						name="seal_number"
						control={control}
						render={({ field }) => (
							<InputText
								{...field}
								disabled={!isCreate && data?.status !== 0}
								errorMessage={errors?.seal_number?.message}
								placeholder="Номер пломбы"
								labelText="Номер пломбы"
							/>
						)}
					/>
				</Grid>
				<Grid item sx={{ width: isDesktop ? 'calc(50% - 4px)' : '100%' }}>
					<Controller
						name="vehicle"
						control={control}
						render={({ field }) => (
							<>
								<VehicleInputAutocomplete
									{...field}
									value={field.value}
									disabled={!isCreate && data?.status !== 0}
									labelText="Номер автомобиля"
									onChange={(newVehicle) => field.onChange(newVehicle?.id)}
								/>
								{errors?.vehicle && (
									<Typography color="error" variant="caption">
										{errors?.vehicle?.message}
									</Typography>
								)}
							</>
						)}
					/>
				</Grid>
				<Grid item sx={{ width: isDesktop ? 'calc(50% - 4px)' : '100%', display: 'flex', gap: '2px', flexDirection: 'column' }}>
					<span>Ожидаемая дата проезда</span>
					<Controller
						name="eta_checkin"
						control={control}
						render={({ field }) => (
							<DateInput
								{...field}
								disabled={!isCreate && data?.status !== 0}
								onChange={(value) => setValue('eta_checkin', value, { shouldTouch: true })}
							/>
						)}
					/>
				</Grid>
				<Grid item sx={{ width: isDesktop ? 'calc(50% - 4px)' : '100%' }}>
					<Controller
						name="assignee"
						control={control}
						render={({ field }) => (
							<AssigneesInputAutocomplete
								{...field}
								disabled={!isCreate && data?.status !== 0}
								group={3}
								valueName={
									data?.assignees ? handleFindWorker({ assignees: data?.assignees, type: 0, item: 'assignee_name' }) : ''
								}
								assigneesHistory={data?.assignees ? filterAssignees({ assignees: data?.assignees, type: 0 }) : []}
								terminal={terminalId}
								labelText="Ответственный менеджер"
							/>
						)}
					/>
				</Grid>
			</Grid>
			<Box sx={entriesForm(isDesktop)}>
				<Button
					variant="outlined"
					color="primary"
					onClick={() => navigate(PAGE_ENTRIES())}
				>
					Назад
				</Button>
				{data?.status == 0 && (
					<Button variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>
						Сохранить
					</Button>
				)}
				{isCreate && (
					<Button variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>
						Создать
					</Button>
				)}
			</Box>
		</FormWrapper>
	);
};
