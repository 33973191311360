import { useCallback, useEffect, useMemo, useState } from "react";
import { useUpdateRepairOrderMutation } from "../../../../../../../store/newApi/service/service";
import { useGetPermissions } from "../../../../../../../shared/auth/hooks/useGetPermissions";
import { REPAIR_STATUSES } from "../../../../../../../shared/repairs/constants";
import { isClient } from "../../../../../../../shared/auth/isClient";
import InputText from "../../../../../../../newComponents/Inputs/InputText/InputText";
import { PageFooterActions } from "../../../../../../../shared/ui/PageFooterActions/PageFooterActions";
import { useNotify } from "shared/providers/AlertProvider/AlertProvider";

export const RepairCommentStep = ({ onCancel, onBack, repairOrderId, serviceOrderInfo }) => {
    const [updateRepairOrder] = useUpdateRepairOrderMutation();
    const [comment, setComment] = useState("");
    const { showNotificaton } = useNotify();

    const { isRemontnik } = useGetPermissions();

    const repairGroups = useMemo(() => serviceOrderInfo?.repair_group || [], [serviceOrderInfo]);
    console.log(serviceOrderInfo);
    const handleFinish = useCallback(() => {
        updateRepairOrder({
            id: repairOrderId,
            base_price: serviceOrderInfo.base_price,
            total_price: serviceOrderInfo.total_price,
            status: isRemontnik ? serviceOrderInfo.status : 1,
            comment
        }).then(() => {
            showNotificaton({ message: 'Успешно сохранено', type: 'success' })
        });
    }, [repairOrderId, serviceOrderInfo, comment, updateRepairOrder, isRemontnik]);

    const handleSaveComment = useCallback(() => {
        updateRepairOrder({ id: repairOrderId, comment }).then(() => {
            showNotificaton({ message: 'Комментарий сохранен', type: 'success' })
        });
    }, [repairOrderId, comment]);

    const isReadyToFinish = useMemo(() => {
        if (isRemontnik)
            return repairGroups.filter(rp => rp.status === REPAIR_STATUSES.ON_REPAIR).length === 0;
        return repairGroups.filter(rp => rp.status !== 1).length === 0 && !isClient();
    }, [repairGroups, isRemontnik]);
    
    useEffect(() => {
        if (serviceOrderInfo?.comment)
            setComment(serviceOrderInfo?.comment)
    }, [serviceOrderInfo]);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', justifyContent: 'space-between', flexGrow: 1 }}>
            <div style={{ padding: '10px' }}>
                {/* @ts-ignore */}
                <InputText
                    defaultValue={serviceOrderInfo?.comment}
                    value={comment}
                    placeholder="Введите текст"
                    height='200px'
                    onChange={(e) => setComment(e.target.value)}
                />
            </div>
            <PageFooterActions
                leftBtns={[
                    {
                        text: 'Отмена',
                        variant: 'outlined',
                        color: 'primary',
                        size: 'medium',
                        onClick: onCancel
                    },
                ]}
                rightBtns={[
                    {
                        text: 'Назад',
                        variant: 'contained',
                        color: 'primary',
                        size: 'medium',
                        onClick: onBack
                    },
                    !isClient() ? {
                        text: 'Завершить',
                        variant: 'contained',
                        color: 'primary',
                        size: 'medium',
                        disabled: !isReadyToFinish,
                        onClick: handleFinish
                    } : {
                        text: 'Сохранить',
                        variant: 'contained',
                        color: 'primary',
                        size: 'medium',
                        onClick: handleSaveComment
                    }
                ]}
            />
        </div>
    )
}