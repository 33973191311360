import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ORDER_INFO_URL } from '../../../../../../../shared/orders/pageUrls';
import { TerminalInputAutocomplete } from '../../../../../../../newComponents/Form/Fields/TerminalInputAutocomplete';
import { ClientInputAutocomplete } from '../../../../../../../newComponents/Form/Fields/ClientInputAutocomplete';
import { useCreateOrderMutation, useUpdateOrderMutation } from '../../../../../../../store/newApi/orders/orders';
import { isClient } from '../../../../../../../shared/auth/isClient';
import { PageFooterActions } from '../../../../../../../shared/ui/PageFooterActions/PageFooterActions';
import { AssigneesInputAutocomplete } from '../../../../../../../newComponents/Form/Fields/AssigneesInputAutocomplete';
import { filterAssignees, handleFindWorker } from '../../../../../../../shared/empls/utils';
import { useNotify } from 'shared/providers/AlertProvider/AlertProvider';
import { tranformErrorsToRender } from 'shared/api/tranformErrorsToRender';

export const ChooseTerminalStep = ({ onBack, refetchOrder, orderId, orderType, defaultOrderData, disabledEdit }) => {
	const { showNotificaton } = useNotify();
	const isClientAuth = isClient();
	const navigate = useNavigate();
	const [createOrder, { data, isLoading, isSuccess, reset: resetCreateOrder }] = useCreateOrderMutation();
	const [terminalId, setTerminalId] = useState(null);
	const [clientId, setClientId] = useState(null);

	const disabledNext = useMemo(
		() => (!isClientAuth && (!terminalId || !clientId)) || (isClientAuth && !terminalId),
		[terminalId, clientId, isClientAuth],
	);
	const isCreateNewFlow = useMemo(() => !defaultOrderData, [defaultOrderData]);
	const [managerAssignee, setManagerAssignee] = useState('');
	const [updateOrder, { reset: resetUpdateOrder }] = useUpdateOrderMutation();

	const onHandleCreateOrder = useCallback(() => {
		let requestData = {
			terminalId,
			status: 0,
			org_id: clientId,
			order_type: +orderType,
		};

		if (managerAssignee) {
			requestData['set_assignee'] = { worker: managerAssignee, type: 0 };
		}
		createOrder({ ...requestData }).then((data) => {
			// @ts-ignore
			if (data.error) {
				showNotificaton({
					type: 'error',
					// @ts-ignore
					message: tranformErrorsToRender(data.error?.data, 'Не удалось создать заявку'),
				});
			} else {
				showNotificaton({
					type: 'success',
					message: 'Заявка успешно создана',
				});
			}
		});
	}, [terminalId, clientId, orderType, createOrder, managerAssignee]);

	const handleGoNext = useCallback(() => {
		if (data && data.terminal.id && data.id) navigate(ORDER_INFO_URL(data.terminal.id, data.id, 1));
	}, [data, navigate]);

	const onHandleContinueBtn = useCallback(() => {
		if (isCreateNewFlow) onHandleCreateOrder();
		else if (
			managerAssignee &&
			managerAssignee !== handleFindWorker({ assignees: defaultOrderData?.assignees, type: 0, item: 'worker' })
		) {
			updateOrder({
				terminalId,
				orderId,
				set_assignee: {
					worker: managerAssignee,
					type: 0,
				},
			}).then(() => {
				refetchOrder();
				resetUpdateOrder();
				showNotificaton({ type: 'success', message: 'Заявка успешно обновлена' });
			});
		}
	}, [isCreateNewFlow, defaultOrderData, onHandleCreateOrder, managerAssignee]);

	useEffect(() => {
		if (!!defaultOrderData) {
			setTerminalId(defaultOrderData?.terminal?.id);
			setClientId(defaultOrderData?.client?.id);
		}
		if (defaultOrderData?.assignees) {
			setManagerAssignee(handleFindWorker({ assignees: defaultOrderData?.assignees, type: 0, item: 'worker' }));
		}
	}, [defaultOrderData]);

	useEffect(() => {
		if (isSuccess) {
			handleGoNext();
			resetCreateOrder();
		}
	}, [isSuccess, resetCreateOrder, handleGoNext]);

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				minHeight: '100%',
				flexGrow: 1,
				justifyContent: 'space-between',
			}}
		>
			<div style={{ display: 'flex', flexDirection: 'column', gap: '12px', maxWidth: '500px', padding: '10px' }}>
				{!isClientAuth && (
					<ClientInputAutocomplete
						labelText="Клиент"
						disabled={!isCreateNewFlow}
						value={clientId}
						onChange={(value) => setClientId(value)}
					/>
				)}
				<TerminalInputAutocomplete
					clientId={clientId}
					labelText="Терминал"
					disabled={!isCreateNewFlow || (!isClientAuth && !clientId)}
					value={terminalId}
					onChange={(value) => setTerminalId(value)}
				/>
				<AssigneesInputAutocomplete
					value={managerAssignee}
					// @ts-ignore
					onChange={(value) => setManagerAssignee(value)}
					group={3}
					valueName={
						defaultOrderData?.assignees
							? handleFindWorker({ assignees: defaultOrderData?.assignees, type: 0, item: 'assignee_name' })
							: ''
					}
					terminal={terminalId}
					assigneesHistory={
						defaultOrderData?.assignees ? filterAssignees({ assignees: defaultOrderData?.assignees, type: 0 }) : []
					}
					// @ts-ignore
					labelText={'Ответственный менеджер'}
				/>
			</div>
			<PageFooterActions
				leftBtns={[
					{
						text: 'Отменить создание',
						variant: 'outlined',
						color: 'primary',
						size: 'medium',
						onClick: onBack,
					},
				]}
				rightBtns={
					!disabledEdit
						? [
								{
									text: isCreateNewFlow ? 'Продолжить' : 'Сохранить',
									variant: 'contained',
									color: 'primary',
									size: 'medium',
									disabled: disabledNext,
									onClick: onHandleContinueBtn,
								},
							]
						: []
				}
			/>
		</div>
	);
};
