export const REPAIR_STATUSES = {
    NOT_ASSESSED: {
        id: 0,
        title: 'Требует оценки'
    },
    ASSESSED: {
        id: 1,
        title: 'Оценено'
    },
    CONFIRMED: {
        id: 2,
        title: 'Одобрено'
    },
    REJECTED: {
        id: 3,
        title: 'Отклонено'
    },
    ON_REPAIR: {
        id: 4,
        title: 'В ремонте'
    },
    REPAIRED: {
        id: 5,
        title: 'Ремонт завершен'
    },
    REPAIR_ACCEPTED: {
        id: 6,
        title: 'Ремонт одобрен'
    },
    REPAIR_REJECTED: {
        id: 7,
        title: 'Ремонт отклонен'
    },
}

export const getReparStatusById = (id: number) => {
    return Object.values(REPAIR_STATUSES).find(status => status.id === id);
}

export const isOnRepair = (id: number | undefined) => {
    return id && id === REPAIR_STATUSES.ON_REPAIR.id;
}

export const getNextStatus = (isRemontnik: boolean, currentStatus: number) => {
    if (isRemontnik) return currentStatus;
    return currentStatus === REPAIR_STATUSES.NOT_ASSESSED.id ? REPAIR_STATUSES.ASSESSED.id : currentStatus;
}