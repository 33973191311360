import { useEffect } from 'react';

export const useInitFormState = ({ data, setOptionsAct, ...methods }) => {
	const { setValue } = methods;

	useEffect(() => {
		if (data) {
			if (data.line) setValue('line', data.line);
			setValue('containerNumber', data.container_number);
			if (data.size) setValue('size', `${data.size}`);
			if (data.type) setValue('type', `${data.type}`);
			setValue('client', data.client);
			if (data.booking) setValue('bookingNumber', data.booking);
			setValue('isFilled', data.is_filled);
			setValue('isDangerous', data.is_dangerous);
			setValue('isDamaged', data.is_damaged);
			setValue('terminal', data.terminal);

			if (data.entry_reports || data.repairs) {
				const entryReportsOptions =
					data.entry_reports?.map((report) => ({
						label: `Акт осмотра №${report.id}`,
						id: report.id,
						type: 'entry_reports',
					})) || [];

				const repairsOptions =
					data.repairs?.map((repairId) => ({
						label: `Акт ремонта №${repairId}`,
						id: repairId,
						type: 'repairs',
					})) || [];

				const combinedOptions = [...entryReportsOptions, ...repairsOptions];
				setOptionsAct(combinedOptions);
			}
		}
	}, [data, setValue, setOptionsAct]);
};
