import { filterTouchedFields } from 'newComponents/Form/utils/filterTouchedFields';
import { useCallback } from 'react';
import { useUpdateTerminalMutation } from 'store/newApi/terminals/terminals';
import { transformSchedule } from './utils';
import { useNotify } from 'shared/providers/AlertProvider/AlertProvider';
import { tranformErrorsToRender } from 'shared/api/tranformErrorsToRender';

export const useHandleUpdate = ({ terminalId, ...methods }) => {
	const { showNotificaton } = useNotify();
	const {
		formState: { touchedFields },
	} = methods;
	const [updateTerminal, { isSuccess: isUpdateSuccess, isLoading: isUpdateLoading, reset: resetUpdateMutation }] =
		useUpdateTerminalMutation();

	const handleUpdate = useCallback(
		(value) => {
			const touchedFieldValues = filterTouchedFields(value, touchedFields);
			const scheduleFilter = transformSchedule(value.schedule);
			const { schedule, ...restTouchedFields } = touchedFieldValues;

			updateTerminal({
				terminalId: terminalId,
				schedule: scheduleFilter,
				...restTouchedFields,
			}).then((data) => {
				if (data.error) {
					showNotificaton({
						type: 'error',
						message: tranformErrorsToRender(data.error?.data, 'Не удалось изменить данные терминала'),
					});
				} else {
					resetUpdateMutation();
					showNotificaton({
						type: 'success',
						message: 'Данные терминала изменены',
					});
				}
			});
		},
		[terminalId, updateTerminal, touchedFields, resetUpdateMutation],
	);

	return {
		handleUpdate,
		isUpdateSuccess,
		isUpdateLoading,
	};
};
