import { InputAutocomplete } from '../../Inputs/InputAutocomplete/InputAutocomplete';
import { useLazyGetTerminalsListQuery } from '../../../store/newApi/terminals/terminals';
import { useEffect } from 'react';

export const TerminalInputAutocomplete = ({ clientId = '', ...rest }) => {
	const [trigger, { terminalOptions = [] }] = useLazyGetTerminalsListQuery({
		selectFromResult: ({ data }) => ({
			terminalOptions: data?.map((e) => ({ label: e.name, id: e.id })) || [],
		}),
	});

	useEffect(() => {
		trigger();
	}, []);

	useEffect(() => {
		if (clientId) {
			trigger({ client_id: clientId });
		}
	}, [trigger, clientId]);

	return <InputAutocomplete {...rest} placeholder="Выберите или введите вручную" options={terminalOptions} />;
};
