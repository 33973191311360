import { useEffect, useMemo } from 'react';
import { useGetExtraservicesListQuery } from 'store/newApi/extraservices/extraservices';

export const useInitFormState = ({ ids, isReady, ...methods }) => {
	const { setValue } = methods;
	const isSingleUpdate = useMemo(() => ids.length === 1, [ids]);
	const { data } = useGetExtraservicesListQuery(
		{ id: ids[0] },
		{
			skip: !isSingleUpdate,
			refetchOnMountOrArgChange: true,
		},
	);

	useEffect(() => {
		if (data?.results[0] && isReady && isSingleUpdate) {
			if (data?.results[0]?.service_name) setValue('service', data?.results[0]?.service_name);
			if (data?.results[0]?.price_per_unit) setValue('price_per_unit', data?.results[0]?.price_per_unit);
			if (data?.results[0]?.quantity) setValue('quantity', data?.results[0]?.quantity);
		}
	}, [data, setValue, isReady, isSingleUpdate]);
};
