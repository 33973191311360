import styles from '../AccountEmpl.module.scss';
import Header from '../../../../newComponents/Header/Header';
import { useCallback, useEffect, useMemo, useState } from 'react';
import TableComponent from '../../../../newComponents/TableComponent/TableComponent';
import { Button } from '@mui/material';
import { formatPrice } from '../../../../helpers/formatText';
import { FILTER_TYPES } from '../../../../config/filter';
import { getExportExcel, resetFilter, setFilter } from '../../../../store/rtkSlices/extraservices.js';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import { useGetTerminalsListQuery } from '../../../../store/newApi/terminals/terminals.js';
import { LINK_CLIENT_BY_ID } from '../../../../shared/clients/pageUrls';
import { isEmpl } from '../../../../shared/auth/isEmpl';
import { Filters } from '../../../../shared/ui/Filters/Filters';
import { ExtraServicesForm } from 'newComponents/Form/ExtraServicesForm/ExtraServicesForm';
import { useLazyGetExtraservicesListQuery } from 'store/newApi/extraservices/extraservices';
import { LINK_CONTAINER_BY_ID } from 'shared/containers/pageUrls';

export const AccountEmplTableExtraServices = () => {
	const userType = Cookies.get('userType');
	const dispatch = useDispatch();
	// Выбор строк
	const [rowSelection, setRowSelection] = useState({});
	const [isFormOpen, setIsFormOpen] = useState(false);
	const [fetch, { data, isLoading, isError }] = useLazyGetExtraservicesListQuery();
	const selectedRows = useMemo(() => Object.keys(rowSelection), [rowSelection]);

	const handleClose = useCallback(() => {
		setRowSelection({});
		setIsFormOpen(false);
	}, [setRowSelection]);

	// колонки
	const columnsList = useMemo(() => {
		const baseColumns = [
			{
				accessorFn: (row) => {
					const url = LINK_CONTAINER_BY_ID(row.container_id);
					return (
						<a href={url} target="_blank" rel="noopener noreferrer nofollow">
							{row.container_number}
						</a>
					);
				},
				id: 'container_number',
				header: '№ контейнера',
				size: 230,
			},
			{
				accessorFn: (row) => row.service_name,
				id: 'service_name',
				header: 'Название',
				size: 250,
			},
			{
				accessorFn: (row) => row.price_per_unit,
				id: 'price_per_unit',
				header: 'Цена за единицу',
				size: 220,
			},

			{
				accessorFn: (row) => row.status_display,
				id: 'status_display',
				header: 'Статус',
				size: 220,
			},

			{
				accessorFn: (row) => row.terminal_display,
				id: 'terminal_display',
				header: 'Терминал',
				size: 220,
			},

			{
				accessorFn: (row) => row.quantity,
				id: 'quantity',
				header: 'Количество',
				size: 200,
			},
			{
				accessorFn: (row) => (row.total_price ? formatPrice(row.total_price) : '-'),
				id: 'total_price',
				header: 'Стоимость',
				size: 200,
			},
		];

		if (userType == 1) {
			baseColumns.push({
				accessorFn: (row) => {
					const url = LINK_CLIENT_BY_ID(row.client);
					return (
						<a href={url} target="_blank" rel="noopener noreferrer nofollow">
							{row.client_name}
						</a>
					);
				},
				id: 'client',
				header: 'Клиент',
				size: 250,
				// @ts-ignore
				sortDescFirst: false,
			});
		}

		return baseColumns;
	}, [userType]);

	// порядок колонок
	const columnOrdering = [
		'mrt-row-select',
		'container_number',
		'terminal_display',
		'client',
		'status_display',
		'service_name',
		'price_per_unit',
		'quantity',
		'total_price',
	];

	const handleSetFilter = (filters) => {
		dispatch(setFilter(filters));
	};

	const handleResetFilter = () => {
		dispatch(resetFilter());
	};

	const { data: dataTerminals } = useGetTerminalsListQuery({});
	const [elements, setElements] = useState([]);

	useEffect(() => {
		if (Array.isArray(dataTerminals)) {
			const newElements = dataTerminals.map((item) => ({
				value: item.id,
				label: item.name,
			}));
			setElements(newElements);
		}
	}, [dataTerminals]);

	const handleExportExcel = () => {
		let viewCols = '';
		if (Object.keys(rowSelection).length !== 0) {
			viewCols += 'id=' + Object.keys(rowSelection).join(',');
		}
		// @ts-ignore
		dispatch(getExportExcel(viewCols));
	};

	const handleAddService = useCallback(() => {
		setRowSelection({});
		setIsFormOpen(true);
	}, []);

	const handleUpdateService = useCallback(() => {
		setIsFormOpen(true);
	}, []);

	return (
		<div className={styles.account__wrap}>
			{/* @ts-ignore */}
			<Header
				text={'Дополнительные услуги'}
				btnDesktop={[
					isEmpl() && selectedRows.length > 0 && (
						<Button variant="contained" color="secondary" size="medium" onClick={handleUpdateService}>
							Редактировать услугу
						</Button>
					),
					<Button variant="contained" color="primary" size="medium" onClick={handleExportExcel}>
						Экспортировать
					</Button>,

					isEmpl() && (
						<Button variant="contained" color="primary" size="medium" onClick={handleAddService}>
							Добавить услугу
						</Button>
					),
				]}
				btnMobile={[
					isEmpl() && selectedRows.length > 0 && (
						<Button variant="contained" color="secondary" size="medium" onClick={handleUpdateService}>
							Редактировать
						</Button>
					),
					<Button variant="contained" color="primary" size="medium" onClick={handleExportExcel}>
						Экспортировать
					</Button>,

					isEmpl() && (
						<Button variant="contained" color="primary" size="medium" onClick={handleAddService}>
							Добавить
						</Button>
					),
				]}
				filter={
					// @ts-ignore
					<Filters
						onSetFilter={handleSetFilter}
						onResetFilter={handleResetFilter}
						parameters={[
							{
								name: 'terminal_id',
								title: 'Терминал',
								type: FILTER_TYPES.INPUTDROPDOWN,
								elements: elements,
							},
							isEmpl() && {
								name: 'client_ids',
								title: 'Клиент',
								type: FILTER_TYPES.INPUTDROPDOWNCLIENT,
							},
						]}
					/>
				}
			/>
			<div className={styles.account__container}>
				<div className={styles.account__stack}>
					<TableComponent
						rowSelection={rowSelection}
						setRowSelection={setRowSelection}
						fetchData={fetch}
						isLoading={isLoading}
						isError={isError}
						data={data}
						columnsList={columnsList}
						columnOrdering={columnOrdering}
						filterName="extraservicesRtk"
					/>
					<ExtraServicesForm
						isOpen={isFormOpen}
						refetchData={() => fetch({ page: 1, page_size: 30 })}
						handleClose={handleClose}
						entityIds={selectedRows}
					/>
				</div>
			</div>
		</div>
	);
};
