import { buildQueryString } from '../../../shared/ui/Filters/utils.js';
import { getValidatedFields } from '../../../shared/api/getValidatedFields.js';
import api from '../../api.js';

export const vehicles = api.injectEndpoints({
	endpoints: (builder) => ({
		getVehiclesList: builder.query({
			query: (params) => {
				let queryParams = { ...params };
				const sortParam = params.sorting?.length
					? params.sorting
							.map(({ id, desc }) => {
								return desc ? `-${id}` : id;
							})
							.join(',')
					: null;

				if (sortParam) {
					queryParams.ordering = sortParam;
				}

				delete queryParams.uniq;
				delete queryParams.sorting;

				return {
					url: `/api/vehicle/vehicles/?${buildQueryString(queryParams)}`,
					method: 'GET',
				};
			},
		}),
		getVahicleById: builder.query({
			query: ({ id }) => {
				return {
					url: `/api/vehicle/${id}/`,
					method: 'GET',
				};
			},
		}),
		updateVehicles: builder.mutation({
			query: ({ ids, ...payload }) => {
				console.log('data, ', payload);
				const validatedData = getValidatedFields(payload);
				return {
					url: `/api/vehicle/batch-update/`,
					method: 'PUT',
					body: {
						vehicles: ids,
						...validatedData,
					},
				};
			},
		}),
		createVehicle: builder.mutation({
			query: ({ ...payload }) => {
				console.log('data, ', payload);
				const validatedData = getValidatedFields(payload);
				return {
					url: `/api/vehicle/vehicle/create/`,
					method: 'POST',
					body: {
						...validatedData,
					},
				};
			},
		}),
	}),
});

export const {
	useGetVehiclesListQuery,
	useLazyGetVehiclesListQuery,
	useCreateVehicleMutation,
	useGetVahicleByIdQuery,
	useUpdateVehiclesMutation,
	useLazyGetVahicleByIdQuery,
} = vehicles;
