import { useEffect, useState, forwardRef } from 'react';
import { Autocomplete, TextField, Button, Box, InputAdornment } from '@mui/material';
import './ExtraservicesInputAutoComplete.scss';
import { ArrowForwardIos } from '@mui/icons-material';
import { useLazyGetServiceListQuery } from 'store/newApi/service/service';
import { ExtraservicesInputAutoCompleteProps } from 'shared/extraservices/types';

const ListboxComponent = forwardRef(function ListboxComponent(props, ref) {
	return (
		<div className="autocomplete-popper" {...props}>
			{/* @ts-ignore */}
			<ul {...props} ref={ref} />
		</div>
	);
});

export const ExtraservicesInputAutoComplete = ({ value, onChange, searchParams, ...rest } : ExtraservicesInputAutoCompleteProps ) => {
	const [fetchExtraservices, { data = [], isLoading, isSuccess }] = useLazyGetServiceListQuery();
	const [options, setOptions] = useState([]);
	const [selectedExtraservices, setSelectedExtraservices] = useState(null);
	const [inputValue, setInputValue] = useState('');
	const [openList, setOpenList] = useState(false);

	useEffect(() => {
		// начальное значение, если строка преобразуется в объект
		if (value) {
			if (typeof value === 'string') {
				setSelectedExtraservices({ name: value });
			} else {
				setSelectedExtraservices(value);
			}
		} else {
			setSelectedExtraservices(null);
		}
	}, [value]);

	useEffect(() => {
		if (searchParams) {
			fetchExtraservices({ page: 1, page_size: 30, ...searchParams, search: 'Мойка' });
		} else fetchExtraservices({ page: 1, page_size: 30, search: 'Мойка' });
	}, [fetchExtraservices, searchParams]);

	useEffect(() => {
		if (isSuccess && openList) {
			setOptions(data.results);
		}
	}, [isSuccess, data, openList, searchParams]);

	const handleInputChange = (event, newInputValue) => {
		setInputValue(newInputValue);
	};

	useEffect(() => {
		if (inputValue) {
			setOptions([]);
		}
	}, [inputValue]);

	const handleOptionChange = (event, newValue) => {
		setSelectedExtraservices(newValue);
		onChange(newValue);
	};

	const handleIfNoOptions = () => {
		onChange(inputValue);
		setSelectedExtraservices({ name: inputValue });
		setOpenList(false);
	};

	const handleInputClick = () => {
		setOpenList(true);
	};

	const handleCloseList = () => {
		setOpenList(false);
	};

	return (
		<div className="autocomplete-wrapper">
			<Autocomplete
				{...rest}
				multiple={false}
				value={selectedExtraservices}
				onChange={handleOptionChange}
				inputValue={inputValue}
				onInputChange={handleInputChange}
				open={openList}
				onOpen={handleInputClick}
				onClose={handleCloseList}
				getOptionLabel={(option) => (option ? option.name : '')}
				options={options}
				loading={isLoading}
				loadingText={<Box className="loading-text">Загрузка...</Box>}
				noOptionsText={
					openList &&
					isSuccess &&
					options.length === 0 && (
						<Box sx={{ padding: '8px' }}>
							<Button color="primary" variant="contained" onClick={handleIfNoOptions}>
								Добавить услугу
							</Button>
						</Box>
					)
				}
				PopperComponent={ListboxComponent}
				renderOption={(props, option) => <li {...props}>{option.name}</li>}
				renderInput={(params) => (
					<Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '2px' }}>
						<TextField
							{...params}
							className={selectedExtraservices ? 'autocomplete-input selected' : 'autocomplete-input'}
							placeholder="Выберите услугу"
							onClick={handleInputClick}
							InputProps={{
								...params.InputProps,
								endAdornment: (
									<InputAdornment position="end">
										<ArrowForwardIos
											style={{
												width: 16,
												height: 16,
												// @ts-ignore
												color: (theme) => theme.palette.text.secondary,
												transform: openList ? 'rotate(90deg)' : 'rotate(0deg)',
												transition: 'transform 0.3s ease',
												cursor: 'pointer',
											}}
										/>
									</InputAdornment>
								),
							}}
						/>
					</Box>
				)}
			/>
		</div>
	);
};
