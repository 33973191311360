import { useGetRepairTasksQuery } from "../../../store/newApi/service/service";
import { InputAutocomplete } from "../../Inputs/InputAutocomplete/InputAutocomplete"

export const RepairTasksInputAutocomplete = ({ searchParams, ...rest }) => {
    const { repairTasksOptions = [] } = useGetRepairTasksQuery({ ...searchParams }, {
        selectFromResult: ({ data }) => ({
            repairTasksOptions: data?.results?.map((t) => ({ label: `${t.repair_component_name}: ${t.name}`, id: t.id })) || []
        })
    });

    return (
        <InputAutocomplete
            {...rest}
            placeholder="Выберите или введите вручную"
            options={repairTasksOptions}
        />
    )
}