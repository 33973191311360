import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { tranformErrorsToRender } from 'shared/api/tranformErrorsToRender';
import { useNotify } from 'shared/providers/AlertProvider/AlertProvider';
import { useCreateLegalEntityMutation } from 'store/newApi/legalEntities/legalEntities';

export const useHandleCreate = ({ ...methods }) => {
	const { showNotificaton } = useNotify();
	const navigate = useNavigate();
	const [
		createLegalEntity,
		{ isSuccess: isCreateSuccess, isLoading: isCreateLoading, reset: resetCreateLegalEntityMutation },
	] = useCreateLegalEntityMutation(); 

	const handleCreate = useCallback(
		(value) => {
			createLegalEntity({ ...value }).then((data) => {
				if (data.error) {
					showNotificaton({
						type: 'error',
						message: tranformErrorsToRender(data.error?.data, 'Не удалось создать юридическое лицо'),
					});
				} else { 
					resetCreateLegalEntityMutation();
					navigate(`/accountEmpl/legal/${data?.data?.id}`);
					showNotificaton({
						type: 'success',
						message: 'Юридическое лицо успешно создано',
					});
				}
			});
		},
		[createLegalEntity],
	);

	return {
		handleCreate,
		isCreateSuccess,
		isCreateLoading,
	};
};
